import { Component, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { CategorySlide, Category, MediaItem, FilterRow } from '../interfaces/model';
import { AudioPlayerPage } from '../audioPlayer/audioPlayer.page';
import { APIService } from '../services/APIService';
import { Events, EventsService } from '../services/EventsService';
import { StorageService } from '../services/StorageService';
import { AppSettingsService } from '../services/AppSettingsService';
import { DownloadService } from '../services/DownloadService';
import { VideoPlayerPage } from '../videoPlayer/videoPlayer.page';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-library',
  templateUrl: 'library.page.html',
  styleUrls: ['library.page.scss'],
})
export class LibraryPage {

  @ViewChild(IonContent) content: IonContent;

  public slideOpts: any;
  public slideOpts1: any;
  public categorySlides: CategorySlide[] = [];
  public activeCategory: Category;
  public items: MediaItem[] = [];
  public filterRows: FilterRow[] = [];
  public showNormalRows: boolean = true;
  public isLoadingLibrary: boolean = true;
  public isNativePlatform: boolean = true;
  
  constructor(
    public _AppSettings: AppSettingsService,
    public _APIService: APIService,
    public _ModalController: ModalController,
    public _StorageService: StorageService,
    public _Events: EventsService,
    public _DownloadService: DownloadService
  ) {
    this.subscribeToEvents();

    this.isNativePlatform = Capacitor.isNativePlatform();

    this.slideOpts = {
      grabCursor: true,
      slidesPerView: 3,
      spaceBetween: 8,
      freeMode: true
    };

    this.slideOpts1 = {
      grabCursor: true,
      slidesPerView: 2,
      spaceBetween: 0,
      freeMode: true
    };

    this.loadData();
  }

  ionViewWillEnter() {
    this.content.scrollToTop(0);
  }
  
  loadData() {
    this._APIService.getLibraryPageModel(Capacitor.isNativePlatform()).then(model => {
      this.categorySlides = (model && model.categorySlides) ? model.categorySlides : [];
      
      // Set all favourites states
      this._StorageService.getIsFavouriteWithMediaItemList(model.items);
      this._StorageService.getIsDownloadedWithMediaItemList(model.items);
      this.items = (model && model.items) ? model.items : [];

      this.activeCategory = (this.categorySlides && this.categorySlides.length > 0) ? this.categorySlides[0].category1 : null;
      this.activate(this.activeCategory);

      this.isLoadingLibrary = false;      
    });
  }

  unsubscribeToEvents() {
    this._Events.unsubscribe(`${Events.ForceUpdatePage_}library`);
    this._Events.unsubscribe(Events.ItemIsFavouriteUpdated_Library);
    this._Events.unsubscribe(Events.ItemIsDownloadedUpdated_Library);
  }

  subscribeToEvents() {
    this.unsubscribeToEvents();

    this._Events.subscribe(`${Events.ForceUpdatePage_}library`, () => {
      this.isLoadingLibrary = true;
      this.loadData();
    });

    this._Events.subscribe(Events.ItemIsFavouriteUpdated_Library, (updatedItem: MediaItem) => {
      if (!updatedItem) { return; }
      var thisItem = this.items.find(i => { return i.id == updatedItem.id; })
      if (!thisItem) { return; }
      thisItem.isFavourite = updatedItem.isFavourite;
      // Reload the filter if we've toggled any favourites.
      if (this.activeCategory.isFavouriteCategory) {
        this.activate(this.activeCategory);
      }
    });

    this._Events.subscribe(Events.ItemIsDownloadedUpdated_Library, (updatedItem: MediaItem) => {
      if (!updatedItem) { return; }
      var thisItem = this.items.find(i => { return i.id == updatedItem.id; })
      if (!thisItem) { return; }

      thisItem.isDownloaded = updatedItem.isDownloaded;
      thisItem.isDownloading = updatedItem.isDownloading;
      thisItem.downloadProgress = updatedItem.downloadProgress;
    
      // Reload the filter if a download has finished.
      if (this.activeCategory.isDownloadedCategory) {
        this.activate(this.activeCategory);
      }
    });
  }

  isActiveCategory(category: Category) {
    if (this.activeCategory == null) { return null; }
    if (this.activeCategory == category) {
      return "active";
    }
  }

  activate(category: Category) {
    this.activeCategory = category;

    var rows: FilterRow[] = [];
    var items: MediaItem[];

    if (!category) { return; }

    if (category.isAllCategory) {
      items = this.items;
      this.showNormalRows = true;
    }
    else if (category.isFavouriteCategory) {
      items = this.items.filter(i => { return i.isFavourite; })
      this.showNormalRows = true;
    }
    else if (category.isDownloadedCategory) {
      items = this.items.filter(i => { return i.isDownloaded || i.isDownloading; })
      this.showNormalRows = true;
    }
    else {
      items = this.items.filter(i => { return i.category.toLowerCase() == category.title.toLowerCase(); })
      this.showNormalRows = false;
    }

    if (this.showNormalRows) {
      items.forEach(i => {
        var existingRow = rows.find(r => { return r.title.toLowerCase() == i.category.toLowerCase(); })      
        if (existingRow) {
          existingRow.items.push(i);
        }
        else {
          var newRow: FilterRow = {
            title: i.category,
            items: [i]
          };        
          rows.push(newRow);
        }
      });
    }
    else {
      items.forEach(i => {
        var existingRow = rows.find(r => { return r.title.toLowerCase() == i.subcategory.toLowerCase(); })      
        if (existingRow) {
          existingRow.items.push(i);
        }
        else {
          var newRow: FilterRow = {
            title: i.subcategory,
            items: [i]
          };        
          rows.push(newRow);
        }
      });
    }

    // make the rows alphabetical always.
    rows = rows.sort((a, b) => { return this.sortByNameAsc(a, b); }); 
    this.filterRows = rows;
  }

  sortByNameAsc(a: any, b: any) {
    if (!a || !a.title || a.title == null) {
      return 0;
    }
    else if (!b || !b.title || b.title == null) {
      return 0;
    }
    else if (a.title.valueOf() < b.title.valueOf()) {
      return -1;
    }
    else if (a.title.valueOf() > b.title.valueOf()) {
      return 1;
    }
    return 0;
  }

  ionImgDidNotLoad(item: any) {
    item.img = this._AppSettings.placeholderImageSrc;
  }

  openPlayer(item: MediaItem) {
    if (item.isVideo) {
      this.openVideoPlayer(item);
    }
    else {
      this.openAudioPlayer(item);
    }
  }

  openVideoPlayer(item: MediaItem) {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: VideoPlayerPage,
      componentProps: {
        item: item
      }
    }).then(player => {
      player.present();
    });
  }

  openAudioPlayer(item: MediaItem) {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: AudioPlayerPage,
      componentProps: {
        item: item
      }
    }).then(player => {
      player.present();
    });
  }

  toggleFavourite(row: FilterRow, item: MediaItem, event: Event) {
    if (event) {
      event.stopPropagation();
    }
    item.isFavourite = !item.isFavourite;
    if (item.isFavourite) {
      this._StorageService.addFavourite(item.id);
    }
    else {
      this._StorageService.removeFavourite(item.id).then(() => {
        this._Events.publish(Events.ItemIsFavouriteUpdated_Library, item);
      });
    }
  }

  toggleDownload(row: FilterRow, item: MediaItem, event: Event) {
    if (event) { event.stopPropagation(); }
    this._DownloadService.toggleDownload(item);
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/AppSettingsService';
import { StorageService } from 'src/app/services/StorageService';
import { startOfWeek } from 'date-fns';
import { format } from 'date-fns';
import { add } from 'date-fns'
import { differenceInDays } from 'date-fns'
import { isBefore } from 'date-fns'
import { CompletedPlaybackItem, CompletedPlaybackModel } from 'src/app/interfaces/model';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-playback-finished',
  templateUrl: './playback-finished.component.html',
  styleUrls: ['./playback-finished.component.scss'],
})
export class PlaybackFinishedComponent {

  @Input() maxMilliseconds: number;

  public completedPlaybackModel: CompletedPlaybackModel = {
    consecutiveDays: null,
    totalDays: null,
    totalHours: null,
    totalMinutes: null,
    mondayHighlighted: false,
    tuesdayHighlighted: false,
    wednesdayHighlighted:false,
    thursdayHighlighted: false,
    fridayHighlighted: false,
    saturdayHighlighted: false,
    sundayHighlighted: false
  }

  constructor(
    public _AppSettings: AppSettingsService,
    public _StorageService: StorageService,
    public _ModalController: ModalController
  ) { 
    this.loadData();
  }

  loadData() {
    // Get list of all completions ever.
    this._StorageService.getCompletedPlaybackHistory().then(history => {
      var today = new Date();
      var todayInt = today.getDay();

      // Pre-check: If device date is reset & last entry is after today, clear.
      if (history.length > 0 
        && isBefore(today, new Date(history[history.length - 1].shortDateString))) {
          history = [];
      }
      
      // Highlight day circles from this week up to today
      // by getting all dates in current week as short dates and finding them in the history, or not.
      var allDatesInCurrentWeek: CompletedPlaybackItem[] = [];
      var formatStr = "yyyy/MM/dd";
      var options: any = { weekStartsOn: 1 };
      var mondayThisWeek = startOfWeek(today, options);

      // Collect all days in this week.
      for (var i = 0; i < 7; i++) {
        var nextDate = add(mondayThisWeek, { days: i });
        allDatesInCurrentWeek.push(<CompletedPlaybackItem> {
          shortDateString: format(nextDate, formatStr),
          dayNumber: nextDate.getDay()
        });        
      }

      var isInHistory = (item: CompletedPlaybackItem, history: CompletedPlaybackItem[]) => {
        return history.find(i => { return i.shortDateString == item.shortDateString; }) != null;
      };

      // Highlight current day always OR if in history this week.
      this.completedPlaybackModel = <CompletedPlaybackModel> {      
        mondayHighlighted: (todayInt == 1 || isInHistory(allDatesInCurrentWeek[0], history)),
        tuesdayHighlighted: (todayInt == 2 || isInHistory(allDatesInCurrentWeek[1], history)),
        wednesdayHighlighted: (todayInt == 3 || isInHistory(allDatesInCurrentWeek[2], history)),
        thursdayHighlighted: (todayInt == 4 || isInHistory(allDatesInCurrentWeek[3], history)),
        fridayHighlighted: (todayInt == 5 || isInHistory(allDatesInCurrentWeek[4], history)),
        saturdayHighlighted: (todayInt == 6 || isInHistory(allDatesInCurrentWeek[5], history)),
        sundayHighlighted: (todayInt == 0 || isInHistory(allDatesInCurrentWeek[6], history))
      };

      // Set total time (days/mins/hours) from this track + history.
      var totalMilliseconds: number = this.maxMilliseconds; 
      history.forEach(item => {
        totalMilliseconds += item.milliseconds;
      });

      var totalSeconds = (totalMilliseconds / 1000);
      this.completedPlaybackModel.totalDays = Math.round(totalSeconds / 86400);
      this.completedPlaybackModel.totalHours = Math.round((totalSeconds % 86400) / 3600);
      this.completedPlaybackModel.totalMinutes = Math.round((totalSeconds % 3600) / 60); 

      // Finally, if last history item is already added, e.g. today, increment the count, otherwise add a new item then save.
      var existingItemIndex = history.findIndex(i => { return i.shortDateString == format(today, formatStr); })
      if (existingItemIndex > -1) {
        history[existingItemIndex].milliseconds += this.maxMilliseconds;
      }
      else {
        history.push(<CompletedPlaybackItem> {
          shortDateString: format(today, formatStr),
          dayNumber: today.getDay(),
          milliseconds: this.maxMilliseconds
        }); 
      }      

      // Find consecutive days now we've added today.
      var consecutiveDays: number = 1;
      if (history.length > 1) {
        var reversedHistory = history.slice().reverse();  // don't mutate original array.      
        var dCurrent: any;
        var dPrevious: any;
        var diffInDays: number = 0;
        for (var i = 0; i < reversedHistory.length - 1; i++) {
          dCurrent = new Date(reversedHistory[i].shortDateString);
          dPrevious = new Date(reversedHistory[i + 1].shortDateString);
          diffInDays = differenceInDays(dCurrent, dPrevious);
          
          if (diffInDays == 1) {
            consecutiveDays++;
          }
          else {
            break;
          }
        }
      }
      this.completedPlaybackModel.consecutiveDays = consecutiveDays;

      // Always update history at the end.
      this._StorageService.setCompletedPlaybackHistory(history);
    });
  }

}

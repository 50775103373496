import { Injectable } from '@angular/core';
import { PushNotification, PushNotificationActionPerformed, PushNotifications, PushNotificationToken } from '@capacitor/push-notifications';

@Injectable()
export class PushService {

    constructor() {
    }

    initPush() {
        this.registerPush();
    }

    private registerPush() {
        PushNotifications.requestPermissions().then((permission) => {
            if (permission.receive) {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // No permission for push granted
            }
        });

        PushNotifications.addListener(
            'registration',
            (token: PushNotificationToken) => {
                console.log('My token: ' + JSON.stringify(token));
            }
        );

        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotification) => {
                console.log('Push received: ' + JSON.stringify(notification));
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: PushNotificationActionPerformed) => {
                const data = notification.notification.data;
                console.log('Action performed: ' + JSON.stringify(notification.notification));
                if (data.detailsId) {
                    //   this.router.navigateByUrl(`/home/${data.detailsId}`);
                }
            }
        );
    }
}
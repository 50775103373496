import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Events, EventsService } from 'src/app/services/EventsService';
import { StorageService } from 'src/app/services/StorageService';
import { SettingsPage } from 'src/app/settings/settings.page';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  public photoSrc: string = '/assets/profile/avatar.png';

  constructor(
    public _ModalController: ModalController,
    public _StorageService: StorageService,
    public _Events: EventsService
  ) { 
    
  }

  ngOnInit() {
    this.loadData();
    this._Events.subscribe(Events.ProfilePhotoUpdated, () => {
      this.loadData();
    });
  }

  ngOnDestroy() {
    this._Events.unsubscribe(Events.ProfilePhotoUpdated);
  }

  showSettings() {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: SettingsPage,
    }).then(modal => {
      modal.present();
    });
  }

  loadData() {
    this._StorageService.getProfilePhoto().then(photo => {
      if (photo) {
        this.photoSrc = photo;
      }
    });
  }

}

import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Events, EventsService } from 'src/app/services/EventsService';


@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
})
export class NetworkComponent {

  public isOffline: boolean = false;
  public showSuggestReloadState: boolean = false;

  constructor(  
    public router: Router,
    public _Zone: NgZone,
    public _Events: EventsService
  ) { 
    if (!Capacitor.isNativePlatform()) {
      return; // hide this control from rendering on web.
    }

    Network.getStatus().then(status => {
      this.setIsOffline(status);
    });

    Network.addListener('networkStatusChange', status => {
      this.setIsOffline(status);
    });
  }

  setIsOffline(status: ConnectionStatus) {
    this._Zone.run(() => {
      var isNowOnline = (status.connected && status.connectionType != 'none');
      if (this.isOffline && isNowOnline) {
        this.showPromptToReloadCurrentPage();
      }
      this.isOffline = (!status.connected || status.connectionType == 'none');
    });
  }

  showPromptToReloadCurrentPage() {
    var page = this.router.url.replace('/', '');
    switch(page) {
      case "home": this.showSuggestReloadState = true; break;
      case "courses": this.showSuggestReloadState = true; break;
      case "library": this.showSuggestReloadState = true; break;
      case "chat": this.showSuggestReloadState = true; break;
      default: this.showSuggestReloadState = false;
    }
  }

  dismiss() {
    this.showSuggestReloadState = false;
  }

  reloadCurrentPage() {
    var page = this.router.url.replace('/', '');
    this.dismiss();
    this._Events.publish(`${Events.ForceUpdatePage_}${page}`);
  }
}


export interface AOMUser {
    name: string;
    uid: string;
    displayName: string;
    photoUrl: string;
    refreshToken: string;
}

export interface HomePageModel {
    featuredItem: FeaturedItem;
    latestItems: LatestItem[];
    eventItems: EventPreview[];
}

export interface FeaturedItem {
    // What type of card
    isYouTubeLive: boolean;
    isBio: boolean;
    isAudio: boolean;
    isVideo: boolean;
    
    // Rendering the card
    img: string;
    category: string;
    title?: string;
    description?: string;
    date?: Date;
    added?: Date;

    // Sub-item audio/video/youtube video id
    mediaItem?: MediaItem;
    youTubeVideoId?: string;
}

export interface LatestItem {
    // What type of card
    isAudio: boolean;
    isVideo: boolean;
    isExternalLink: boolean;
    
    // Rendering the card
    img: string;
    category: string;
    title?: string;
    added?: Date;
    
    // Sub-item audio/video/external link url
    mediaItem?: MediaItem;
    externalLinkUrl?: string;
    externalLinkSubtitle?: string;
}

export interface BioPreview {
    img: string;
    category: string;
    title?: string;
    description?: string;
    date?: Date;
}

export interface EventPreview {
    img: string;
    category: string;
    title?: string;
    description?: string;
    location?: string;
    date?: Date;
}


export interface CoursePageModel {
    courses: Course[];
}

export interface LibraryPageModel {
    categorySlides: CategorySlide[];
    items: MediaItem[];
}

export interface CategorySlide {
    category1: Category;
    category2: Category;
}

export interface Category {
    id?: string;
    title: string;
    isAllCategory?: boolean;
    isFavouriteCategory?: boolean;
    isDownloadedCategory?: boolean;
    isHidden?: boolean;
}

export interface MediaItem {
    id?: string;
    title: string;
    img?: string;
    url: string;    
    category: string;
    subcategory?: string;
    duration: string;
    added?: string;
    isVideo?: boolean;
    isFavourite?: boolean;
    isDownloaded?: boolean;
    isCourseItem?: boolean;
    isAlreadyPlayed?: boolean;
    isDownloading?: boolean;
    downloadProgress?: number;
    isCourseIntroItem?: boolean;
}


export interface Course {
    isComingSoon: boolean;
    id?: string;
    imgCardUrl?: string;
    imgPlayerUrl?: string;
    title: string;
    description?: string;
    longDescription?: string;
    numberOfHours?: string;
    numberOfWeeks: number;
    lessonGroups?: LessonGroup[];
    isLocked: boolean;
    introMediaItemUrl: string;
    introMediaItemIsVideo?: boolean;
    courseWebLink?: string;

    // runtime bits
    totalCompleted?: number;
    totalItems?: number;
}

export interface LessonGroup {
    title?: string;
    description?: string;
    indexOrder: number;
    items: MediaItem[];
}

export interface FilterRow {
    title: string;
    items: MediaItem[];
}

export interface UserNotifications {
    meditationReminderOn: boolean;
    meditationReminderTime: string;
    meditationReminderFrequency: NotificationFrequency;
}

export enum NotificationFrequency {
    EveryDay = -1, 
    Monday = 1,   
    Tuesday = 2,  
    Wednesday = 3,    
    Thursday = 4,
    Friday = 5, 
    Saturday = 6,
    Sunday = 7 
}

export interface CompletedPlaybackModel {
    consecutiveDays: number;
    totalDays: number;
    totalHours: number;
    totalMinutes: number;
    mondayHighlighted: boolean;
    tuesdayHighlighted: boolean;
    wednesdayHighlighted: boolean;
    thursdayHighlighted: boolean;
    fridayHighlighted: boolean;
    saturdayHighlighted: boolean;
    sundayHighlighted: boolean;
}

export interface CompletedPlaybackItem {
    shortDateString: string;
    dayNumber: number;
    milliseconds: number;
}

export interface YouTubeItem {
    videoId: string;
    title: string;
    duration?: string;
    thumbnail: string;
    publishedAt: string;
}

export interface ChatRoom {
    firebaseCollectionId: string;
    imgUrl: string;
    title: string;
}

export interface ChatMessage {
    id: string;
    humanDate: string;
    name: string;
    message: string;
    user: string;
    userLetter?: string;
    isMe: boolean;
}

export interface NextLesson {
    courseTitle: string;
    lessonGroupTitle: string;
    item: MediaItem;
}

export interface TabItem {
    name: string; 
    onIcon: string;
    offIcon: string;
    isSelected: boolean;
}
import { Component, ViewChild } from '@angular/core';
import { AlertController, IonContent, ModalController, NavController } from '@ionic/angular';
import { ChatRoomPage } from '../chatRoom/chatRoom.page';
import { customPageAnimation_IN, customPageAnimation_OUT } from '../custom-animations/customPageAnimation';
import { AOMUser, ChatRoom } from '../interfaces/model';
import { AppSettingsService } from '../services/AppSettingsService';
import { StorageService } from '../services/StorageService';

@Component({
  selector: 'app-chat',
  templateUrl: 'chat.page.html',
  styleUrls: ['chat.page.scss'],
})
export class ChatPage {

  @ViewChild(IonContent) content: IonContent;

  public chatRoomMetta: ChatRoom;
  public chatRoomDaily: ChatRoom;
  public chatRoomDharma: ChatRoom;
  public chatRoomPost: ChatRoom;

  constructor(
    public _AppSettings: AppSettingsService,
    public _ModalController: ModalController,
    public _StorageService: StorageService,
    public _AlertController: AlertController
  ) {
    this.chatRoomMetta = <ChatRoom> { firebaseCollectionId: 'metta', title: 'Metta Support', imgUrl: '/assets/chat/metta.jpg'};
    this.chatRoomDaily = <ChatRoom> { firebaseCollectionId: 'practice', title: 'Daily Practice', imgUrl: '/assets/chat/daily.jpg'};
    this.chatRoomDharma = <ChatRoom> { firebaseCollectionId: 'dharma', title: 'Dharma', imgUrl: '/assets/chat/dharma.jpg'};
    this.chatRoomPost = <ChatRoom> { firebaseCollectionId: 'retreat', title: 'Post Retreat', imgUrl: '/assets/chat/post.jpg'};
  }

  ionViewWillEnter() {
    this.content.scrollToTop(0);
  }

  ionImgDidNotLoad(chatRoom: ChatRoom) {
    chatRoom.imgUrl = this._AppSettings.placeholderImageSrc;
  }

  goToChatRoom(chatRoom: ChatRoom) {
    // Check we've got the name 
    this._StorageService.getAOMUser().then(user => {
      if (!user.name) {
        this.getName(user, chatRoom);
      }
      else {
        this.goToChatRoomFinish(user, chatRoom);
      }
    });
  }

  getName(user: AOMUser, chatRoom: ChatRoom) {
    this._AlertController.create({
      mode: 'md',
      header: 'Enter Name',
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: 'Type here'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            if (data.name && data.name.trim().length > 0) {
              user.name = data.name.trim();
              this._StorageService.setAOMUser(user);
              this.goToChatRoomFinish(user, chatRoom);
            }
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  goToChatRoomFinish(user: AOMUser, chatRoom: ChatRoom) {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: ChatRoomPage,
      componentProps: {
        user: user,
        chatRoom: chatRoom
      },
      enterAnimation: customPageAnimation_IN,
      leaveAnimation: customPageAnimation_OUT
    }).then(modal => {
      modal.present();
    });
  }

}

import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { Events, EventsService } from '../services/EventsService';

import { MediaItem, YouTubeItem } from "../interfaces/model";
import { DomSanitizer } from '@angular/platform-browser';
import { OrientationChoice, RemoteAudio } from '../../../../../Capacitor Plugins/capacitor-plugin-remote-audio/dist/esm';
import { StorageService } from '../services/StorageService';

declare var cordova: any;
declare var YT: any;

@Component({
  selector: 'app-youtubePlayer-player',
  templateUrl: 'youtubePlayer.page.html',
  styleUrls: ['youtubePlayer.page.scss'],
})
export class YoutubePlayerPage {

  public ik_player: any;
  public youtubeItem: YouTubeItem;
  public showPlayer: boolean = false;
  public playerUrl: string;
  public mediaItemId: string;
  public markViewedOnClose: boolean;

  constructor(
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _ToastController: ToastController,
    public _Zone: NgZone,
    public _Events: EventsService,
    public _DomSanitizer: DomSanitizer,
    public _StorageService: StorageService
  ) {
    this.subscribeToEvents();

    this.mediaItemId = this._Params.get('mediaItemId');
    this.markViewedOnClose = this._Params.get('markViewedOnClose');
    
    var item = this._Params.get('item'); 
    this.youtubeItem = item;
    this.playerUrl = "https://www.youtube.com/embed/" + this.youtubeItem.videoId
    + "?enablejsapi=1";
    this.showPlayer = true;
  }

  ionViewWillEnter() {
    RemoteAudio.setOrientation({ orientation: OrientationChoice.Unlocked })
  }

  ionViewWillLeave() {
    this.unsubscribeToEvents();
    RemoteAudio.setOrientation({ orientation: OrientationChoice.Portrait })
    this.updateIsAlreadyPlayedIfCourseItem();
  }

  updateIsAlreadyPlayedIfCourseItem() {
    if (this.mediaItemId == null || !this.markViewedOnClose) { return; }

    this._StorageService.addIsAlreadyPlayed(this.mediaItemId).then(() => {
      this._Events.publish(Events.ItemIsAlreadyPlayedUpdated_Courses, <MediaItem> { id: this.mediaItemId, isAlreadyPlayed: true });
    });
  }

  unsubscribeToEvents() {
  }

  subscribeToEvents() {
  }

  openYoutubeApp() {
    window.open(`https://www.youtube.com/watch?v=${this.youtubeItem.videoId}`, "_blank");
  }

}

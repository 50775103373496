import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';


@Component({
  selector: 'app-gdpr',
  templateUrl: 'gdpr.page.html',
  styleUrls: ['gdpr.page.scss'],
})
export class GDPRPage {

  constructor(
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _DomSanitizer: DomSanitizer
  ) {

  }

  close() {    
    this._ModalController.dismiss();
  }

}

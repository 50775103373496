import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { IonRange, ModalController, NavParams, ToastController } from '@ionic/angular';
import { RemoteAudio } from 'capacitor-plugin-remote-audio';
import { Events, EventsService } from '../services/EventsService';
import { LocalNotificationsService } from '../services/LocalNotificationsService';
import { MediaItem } from '../interfaces/model';
import { StorageService } from '../services/StorageService';
import { Filesystem, FilesystemDirectory, FilesystemEncoding } from '@capacitor/filesystem';
import { AppSettingsService } from '../services/AppSettingsService';
import { DownloadService } from '../services/DownloadService';
import { Capacitor } from '@capacitor/core';


declare var cordova: any;

@Component({
  selector: 'app-audio-player',
  templateUrl: 'audioPlayer.page.html',
  styleUrls: ['audioPlayer.page.scss'],
})
export class AudioPlayerPage {

  @ViewChild(IonRange) ionRange: IonRange;

  public autoPlay: boolean = true;
  public finishedBackgroundUrl: string;
  public isFinishedPlaying: boolean = false;
  public isPlaying: boolean = false;
  public bgImg: string;
  public subtitle: string;

  public currentMilliseconds: number = 0;
  public currentDisplayPosition: any = "0:00";
  public displayDuration: any;
  public currentMedia: MediaItem;
  public maxMilliseconds: number = -1; 

  public timer: any;
  public rangeActive: boolean = false;
  public showSeekHint: boolean = false;

  public introOnlyPlayerMode: boolean;
  public isNativePlatform: boolean = true;

  constructor(
    public _AppSettings: AppSettingsService,
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _ToastController: ToastController,
    public _Zone: NgZone,
    public _Events: EventsService,
    public _LocalNotificationsService: LocalNotificationsService,
    public _StorageService: StorageService,
    public _DownloadService: DownloadService
  ) {
    this.isNativePlatform = Capacitor.isNativePlatform();

    var item = this._Params.get('item');

    var introOnlyPlayerMode = this._Params.get('introOnlyPlayerMode');
    this.introOnlyPlayerMode = (introOnlyPlayerMode != null && introOnlyPlayerMode);

    this.currentMedia = item;
    this.bgImg = item.img;
    this.subtitle = this._Params.get('subtitle');
    this.finishedBackgroundUrl = _AppSettings.finishedBackgroundUrl;

    this.subscribeToEvents();

    // If marked as downloaded, try to get local file instead of streaming.
    if (this.currentMedia.isDownloaded) {
      Filesystem.getUri({
        path: `${_AppSettings.androidDownloadFolder}/${this.currentMedia.id}`,
        directory: FilesystemDirectory.ExternalStorage,
      }).then(result => {
        if (result && result.uri) {
          var dotAndExtension = this.currentMedia.url.substring(this.currentMedia.url.lastIndexOf('.'));
          this.currentMedia.url = result.uri + dotAndExtension;
          this.loadData();
        }
      });
    }
    else {
      this.loadData();
    }
  }

  loadData() {
    if (this.currentMedia && !this.currentMedia.url) {
      this._LocalNotificationsService.showAlert("Oops!", "No media to play.",);
      return;
    }

    RemoteAudio.getMediaInfo(this.currentMedia).then(data => {
      if (data && data.info && data.info.duration != null && data.info.duration != "") {  
        this.maxMilliseconds = data.info.duration;
        this.displayDuration = this.millisToMinutesAndSeconds(data.info.duration);

        if (this.autoPlay) {
          this.play();
        }
      }
      else {
        this._ModalController.dismiss().then(() => {
          this._LocalNotificationsService.showToast('There was a problem loading the media :(');
        });
      }
    });

    this._StorageService.getIsFavourite(this.currentMedia.id).then(isFavourite => {
      this.currentMedia.isFavourite = isFavourite;
    });
  }

  ionViewWillLeave() {
    this.unsubscribeToEvents();
    if (this.currentMedia && this.currentMedia.url) {
      this.stop();
    }
  }

  unsubscribeToEvents() {
    this._Events.unsubscribe(Events.UpdateAppUI_WasPaused);
    this._Events.unsubscribe(Events.UpdateAppUI_WasPlayed);    
  }

  subscribeToEvents() {
    this.unsubscribeToEvents();
    this._Events.subscribe(Events.UpdateAppUI_WasPaused, () => {
      this._Zone.run(() => {
        this.isPlaying = false;
        this.stopTimer();
      });
    });

    this._Events.subscribe(Events.UpdateAppUI_WasPlayed, () => {
      this._Zone.run(() => {
        this.isPlaying = true;
        this.startTimer();
      });
    });
  }

  play() {
    // Avoid waiting for cpacitor to update UI, but update later if it fails.
    this.isPlaying = true;

    RemoteAudio.play(this.currentMedia).then(result => {
      this.isPlaying = (result.isPlaying);
      this.startTimer();
      if (!result.isPlaying) {
        this.stop();
      }     
    });
  }

  pause() {
    RemoteAudio.pause();
    this.isPlaying = false;
    this.stopTimer();
  }

  stop() {
    RemoteAudio.stop();
    this.isPlaying = false;
    this.stopTimer();
  }

  rewind() {
    var val = this.currentMilliseconds - 10000;
    this.updateSlider(val);
    RemoteAudio.seek({ milliseconds: this.currentMilliseconds});        
  }

  fastForward() {
    var val = this.currentMilliseconds + 10000;
    this.updateSlider(val);
    RemoteAudio.seek({ milliseconds: this.currentMilliseconds});        
  }

  finishedPlaying() {
    if (this.introOnlyPlayerMode) {
      this.isPlaying = false;
      this.stopTimer();
      return setTimeout(() => {
        this._ModalController.dismiss();
      }, 1000);
    }

    this.bgImg = this.finishedBackgroundUrl;
    this.isPlaying = false;
    this.isFinishedPlaying = true;
    this.stopTimer();
    this.updateIsAlreadyPlayedIfCourseItem();
  }

  startTimer() {
    if (this.timer == null) {
      this.timer = setInterval(() => {
        RemoteAudio.getCurrentPosition().then(result => {
          // console.log(`getCurrentPosition result: ${JSON.stringify(result)}`);
          if (result && result.position != null) {
            this.updateSlider(result.position);
          }
        });  
      }, 1000);

      // console.log('Timer started with Id: ' + this.timer);
    }
  }

  stopTimer() {
    if (this.timer != null) {
      clearInterval(this.timer);
      // console.log('Timer cleared with Id: ' + this.timer);
      this.timer = null;
    }
  }

  updateSlider(val: number) {
    // we don't want to update the slider whilst we're sliding!
    if (this.rangeActive) { return; }

    if (val >= this.maxMilliseconds) {
      this.currentMilliseconds = this.maxMilliseconds;
      if (this.isPlaying) {
        this.finishedPlaying();
      }
    }
    else {
      this.currentMilliseconds = (val <= 0) ? 0 : val;
    }

    this.currentDisplayPosition = this.millisToMinutesAndSeconds(this.currentMilliseconds);
  }
  
  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = Number(((millis % 60000) / 1000).toFixed(0));
    return (seconds == 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
  }

  activateRangeFinished() {
    if (this.rangeActive) { 
      this.rangeActive = false;
      
      var val = Number(this.ionRange.value.valueOf());
      this.updateSlider(val);
      RemoteAudio.seek({ milliseconds: val });

      setTimeout(() => {
        this.showSeekHint = false;
      }, 200);
    }
  }

  activateRange()  { 
    this.rangeActive = true; 
    this.showSeekHint = true;
  }

  toggleFavourite() {
    this.currentMedia.isFavourite = !this.currentMedia.isFavourite;
    if (this.currentMedia.isFavourite) {
      this._StorageService.addFavourite(this.currentMedia.id);
    }
    else {
      this._StorageService.removeFavourite(this.currentMedia.id);
    }

    this._Events.publish(Events.ItemIsFavouriteUpdated_Courses, <MediaItem> {
      id: this.currentMedia.id,
      isFavourite: this.currentMedia.isFavourite
    });

    this._Events.publish(Events.ItemIsFavouriteUpdated_Library, <MediaItem> {
      id: this.currentMedia.id,
      isFavourite: this.currentMedia.isFavourite
    });
  }

  updateIsAlreadyPlayedIfCourseItem() {
    if (!this.currentMedia.isCourseItem) { return; }
    this._StorageService.addIsAlreadyPlayed(this.currentMedia.id).then(() => {
      this.currentMedia.isAlreadyPlayed = true;
      this._Events.publish(Events.ItemIsAlreadyPlayedUpdated_Courses, this.currentMedia);
    });
  }
  
  

  toggleDownload(item: MediaItem, event: Event) {
    if (event) { event.stopPropagation(); }
    this._DownloadService.toggleDownload(item);
  }


}

import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { OrientationChoice, RemoteAudio } from 'capacitor-plugin-remote-audio';
import { MediaItem } from '../interfaces/model';
import { AppSettingsService } from '../services/AppSettingsService';
import * as videojs from 'video.js';
import { Filesystem, FilesystemDirectory } from '@capacitor/filesystem';
import { Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { LocalNotificationsService } from '../services/LocalNotificationsService';


@Component({
  selector: 'app-videoPlayer-player',
  templateUrl: 'videoPlayer.page.html',
  styleUrls: ['videoPlayer.page.scss'],
})
export class VideoPlayerPage {

  public videoElementId: string = 'myPlayer'; 
  public bgStyle: any;
  public finishedBackgroundUrl: string;
  public maxMilliseconds: number;
  public showPlayer: boolean = false;
  public isFinishedPlaying: boolean = false;
  public currentMedia: MediaItem;
  public videoJS = videojs.default;
  public introOnlyPlayerMode: boolean;
  
  constructor(
    public _AppSettings: AppSettingsService,
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _LocalNotificationService: LocalNotificationsService
  ) {
    var item = this._Params.get('item');
    var introOnlyPlayerMode = this._Params.get('introOnlyPlayerMode');
    this.introOnlyPlayerMode = (introOnlyPlayerMode != null && introOnlyPlayerMode);

    this.currentMedia = item;
    this.finishedBackgroundUrl = _AppSettings.finishedBackgroundUrl;

    // If marked as downloaded, try to get local file instead of streaming.
    if (this.currentMedia.isDownloaded) {
      Filesystem.getUri({
        path: `${_AppSettings.androidDownloadFolder}/${this.currentMedia.id}`,
        directory: FilesystemDirectory.ExternalStorage,
      }).then(result => {
        if (result && result.uri) {
          var dotAndExtension = this.currentMedia.url.substring(this.currentMedia.url.lastIndexOf('.'));
          var localUrl: string = result.uri + dotAndExtension;
          if (Capacitor.isNativePlatform()) {
            localUrl = Capacitor.convertFileSrc(localUrl);
          }
          this.currentMedia.url = localUrl;
          this.loadData();
        }
      }).catch(e => {
        this._LocalNotificationService.showAlert('Error', 'Couldn\'t load downloaded file :(');
      });
    }
    else {
      this.loadData();
    }
    
    this.subscribeToEvents();
  }

  ionViewWillEnter() {
    RemoteAudio.setOrientation({ orientation: OrientationChoice.Unlocked })
  }

  ionViewWillLeave() {
    this.destroyPlayer();
    this.unsubscribeToEvents();
    RemoteAudio.setOrientation({ orientation: OrientationChoice.Portrait })
  }

  unsubscribeToEvents() {
  }

  subscribeToEvents() {
  }

  loadData() {
    this.showPlayer = true;
    this.waitForElementToAppear(this.videoElementId).then(() => {
      this.videoJS(this.videoElementId, {
        controls: true,
        preload: 'auto',        
      }).ready(() => {
        // this.showFakePlayButton = true;
        var player = this.videoJS(this.videoElementId);
        
        player.on('error', () => {          
          var msg = (player.error() && player.error().message) ?
            player.error().message : 'There was a problem playing the video sorry.';
          this._LocalNotificationService.showAlert('Playback Error', msg);
        });

        player.on('loadedmetadata', () => {          
          this.maxMilliseconds = (player.duration() * 1000);
        });

        player.on('timeupdate', () => {
          if (player.ended()) {
            this.finishedPlaying();
          }
        });

        setTimeout(() => {
          this.startPlayback()
        }, 250);
      });
    });
  }

  startPlayback() {
    this.videoJS(this.videoElementId).play().then(() => {
      setTimeout(() => {        
        document.getElementById('loadingCover').remove();
      }, 250);
    }).catch(e => {
      //whatever
    });
  }

  destroyPlayer() {
    try {
      this.videoJS(this.videoElementId).dispose();
    }
    catch(e) {
      // already destroyed, doesn't matter.
    }    
  }

  finishedPlaying() {
    RemoteAudio.setOrientation({ orientation: OrientationChoice.Portrait }).then(() => {
      if (this.introOnlyPlayerMode) {
        return setTimeout(() => {
          this.destroyPlayer();
          this.showPlayer = false;
          this._ModalController.dismiss();
        }, 1500);
      }

      this.bgStyle = `background-image: url('${this.finishedBackgroundUrl}')`;
      this.isFinishedPlaying = true;
    });
  }

  waitForElementToAppear(elementId): Promise<any> {
    return new Observable(function (observer) {
      var el_ref;
      var f = () => {
        el_ref = document.getElementById(elementId);
        if (el_ref) {
          observer.next(el_ref);
          observer.complete();
          return;
        }
        window.requestAnimationFrame(f);
      };
      f();
    }).toPromise();
  }
}

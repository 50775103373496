import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/AppSettingsService';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
})
export class PayPalComponent {

  constructor(
    public _AppSettings: AppSettingsService
  ) { 
    
  }

  paypal() {
    window.open(this._AppSettings.donateUrl, "_blank");
  }

}

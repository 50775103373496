import { Injectable, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { CategorySlide, CoursePageModel, Course, HomePageModel, Category, LibraryPageModel, MediaItem, YouTubeItem, FeaturedItem, LatestItem, EventPreview } from '../interfaces/model';
import { HttpClient } from '@angular/common/http';
import { format, minTime } from 'date-fns';
import { StorageService } from './StorageService';
import { AppSettingsService } from './AppSettingsService';


@Injectable()
export class APIService {

    private httpsProtocol: string = 'https:';

    constructor(
        public _AppSettings: AppSettingsService,
        public _http: HttpClient,
        public _StorageService: StorageService,
    ) { }

    getContentDate(item: any) {
        if (!item || (item && !item.sys)) { return null; }
        try {
            var rtnD;
            
            if (item.sys.updatedAt) {
                rtnD = new Date(item.sys.updatedAt);
            }
            else if (item.sys.createdAt) {
                rtnD = new Date(item.sys.createdAt);
            }
            return rtnD;
        }
        catch(E) {
            return null;
        }
    }

    getApiCallUrl(path: string) {
        return this._AppSettings.contentfulAPIBaseUrl + path;
    }
 
    getHomePageModel(): Promise<HomePageModel> {
        return new Promise(resolve => {
            var model = <HomePageModel> {
                featuredItem: null,
                latestItems: null,
                eventItems: null
            };

            var a = this.getHomePageFeaturedItem().then(featuredItem => {
                model.featuredItem = featuredItem;
            });

            var b = this.getHomePageLatestItems().then(latestItems => {
                model.latestItems = latestItems;
            });

            var c = this.getHomePageEventItems().then(eventItems => {
                model.eventItems = eventItems;
            });

            Promise.all([a,b,c]).then(() => {
                resolve(model);
            });
        });
    }

    getHomePageFeaturedItem(): Promise<FeaturedItem> {
        return new Promise(resolve => {
            this._http.get(this.getApiCallUrl(this._AppSettings.featuredItemContentPath), { headers: { Authorization: `Bearer ${this._AppSettings.authToken}` }}).toPromise().then(res => {
                var data: any = res;
                if (data && data.items && data.items.length > 0) {
                    var item = data.items[0];

                    var imgId = (item.fields.image) ? item.fields.image.sys.id : null;
                    var imageAsset = (imgId && data.includes && data.includes.Asset) ? 
                        data.includes.Asset.find(a => { 
                            return a.sys.id == imgId; 
                        }) : null;

                    var tag = (item.metadata && item.metadata.tags && item.metadata.tags.length > 0) ? 
                    item.metadata.tags[0].sys.id : null;

                    var mediaItemId = (item.fields.mediaItem) ? item.fields.mediaItem.sys.id : null;

                    var featuredItem: FeaturedItem = { 
                        isYouTubeLive: item.fields.isYouTube,
                        isBio: item.fields.isBio,
                        isAudio: item.fields.isAudio,
                        isVideo: item.fields.isVideo,
                        img: (imageAsset) ? imageAsset.fields.file.url : null,
                        category: this.wordSplit(tag),
                        title: item.fields.title,
                        description: item.fields.description,
                        date: (item.fields.date) ? new Date(item.fields.date) : null,
                        added: this.getContentDate(item)
                    };

                    if (featuredItem.img && !featuredItem.img.startsWith(this.httpsProtocol)) {
                        featuredItem.img = this.httpsProtocol + featuredItem.img;
                    }

                    // Set associated child-item (youtube, bio, audio or video)
                    if ((featuredItem.isAudio || featuredItem.isVideo) && mediaItemId) {
                        var entry = data.includes.Entry.find(e => { return e.sys.id == mediaItemId; })
                        
                        var itemPr;
                        var itemSec;         

                        if (entry && entry.metadata && entry.metadata.tags && entry.metadata.tags.length > 0
                            && itemPr && itemSec) {
                            itemPr = entry.metadata.tags.find(t => { return t.sys.id.startsWith('category'); })
                            itemSec = entry.metadata.tags.find(t => { return !t.sys.id.startsWith('category'); })
                        } 

                        var imgId = (entry && entry.fields && entry.fields.image) ? entry.fields.image.sys.id : null;
                        var mediaId = (entry && entry.fields && entry.fields.media) ? entry.fields.media.sys.id : null;
                        var durationInMinutes = (entry && entry.fields && entry.fields.media) ? entry.fields.media.durationInMinutes : null;
                        var title = (entry && entry.fields && entry.fields) ? entry.fields.title : null;
                        var imgAsset = (imgId && data.includes.Asset) ? data.includes.Asset.find(a => { return a.sys.id == imgId; }) : null
                        var mediaAsset = (mediaId && data.includes.Asset) ? data.includes.Asset.find(a => { return a.sys.id == mediaId; }) : null

                        var mediaItem = <MediaItem> {   
                            id: mediaItemId,
                            title: (title) ? title : item.fields.title,
                            category: (itemPr) ? this.wordSplit(itemPr.sys.id.replace('category', '')) : null,
                            subcategory: (itemSec) ? this.wordSplit(itemSec.sys.id) : null,
                            duration: (durationInMinutes != null) ? `${durationInMinutes} mins` : null,
                            img: (imgAsset) ? imgAsset.fields.file.url : null,
                            url: (mediaAsset) ? mediaAsset.fields.file.url : null
                        };

                        if (mediaItem.img && !mediaItem.img.startsWith(this.httpsProtocol)) {
                            mediaItem.img = this.httpsProtocol + mediaItem.img;
                        }

                        if (mediaItem.url && !mediaItem.url.startsWith(this.httpsProtocol)) {
                            mediaItem.url = this.httpsProtocol + mediaItem.url;
                            mediaItem.isVideo = this.getIsVideoItem(mediaAsset);
                        }

                        featuredItem.mediaItem = mediaItem;
                    }

                    if (featuredItem.isYouTubeLive && item.fields.youTubeVideoUrl) {
                        var ytVId = this.getYouTubeVideoIdFromString(item.fields.youTubeVideoUrl);
                        featuredItem.youTubeVideoId = (ytVId && ytVId != item.fields.youTubeVideoUrl) ? ytVId : null; 
                    }
                }

                this._StorageService.setOfflineHomePageFeaturedItem(featuredItem);
                resolve(featuredItem);
            }).catch(e => {
                console.log(e);
                // Fallback to offline
                this._StorageService.getOfflineHomePageFeaturedItem().then(offlineitem => {
                    resolve(offlineitem ? offlineitem : null);
                });
            })
        });
    }

    getHomePageLatestItems(): Promise<LatestItem[]> {
        return new Promise(resolve => {
            var latestItems: LatestItem[];

            this._http.get(this.getApiCallUrl(this._AppSettings.latestItemsContentPath), { headers: { Authorization: `Bearer ${this._AppSettings.authToken}` }}).toPromise().then(res => {
                var data: any = res;
                if (data && data.items && data.items.length > 0) {
                    
                    for (var i = 0; i < data.items.length; i++) {                        
                        var item = data.items[i];
                        var imgId = (item.fields.image) ? item.fields.image.sys.id : null;
                        var imageAsset = (imgId && data.includes && data.includes.Asset) ? 
                            data.includes.Asset.find(a => { 
                                return a.sys.id == imgId; 
                            }) : null;
    
                        var tag = (item.metadata && item.metadata.tags && item.metadata.tags.length > 0) ? 
                        item.metadata.tags[0].sys.id : null;
    
                        var mediaItemId = (item.fields.mediaItem) ? item.fields.mediaItem.sys.id : null;
    
                        var latestItem: LatestItem = { 
                            isAudio: item.fields.isAudio,
                            isVideo: item.fields.isVideo,
                            isExternalLink: item.fields.isExternalLink,
                            img: (imageAsset) ? imageAsset.fields.file.url : null,
                            category: this.wordSplit(tag),
                            title: item.fields.title,
                            added: this.getContentDate(item)
                        };
    
                        if (latestItem.img && !latestItem.img.startsWith(this.httpsProtocol)) {
                            latestItem.img = this.httpsProtocol + latestItem.img;
                        }
    
                        // Set associated child-item (youtube, bio, audio or video)
                        if ((latestItem.isAudio || latestItem.isVideo) && mediaItemId) {
                            var entry = data.includes.Entry.find(e => { return e.sys.id == mediaItemId; })
                            
                            var itemPr;
                            var itemSec;         
                            
                            if (entry && entry.metadata && entry.metadata.tags && entry.metadata.tags.length > 0) {
                                itemPr = entry.metadata.tags.find(t => { return t.sys.id.startsWith('category'); })
                                itemSec = entry.metadata.tags.find(t => { return !t.sys.id.startsWith('category'); })
                            } 
    
                            var imgId = (entry && entry.fields && entry.fields.image) ? entry.fields.image.sys.id : null;
                            var mediaId = (entry && entry.fields && entry.fields.media) ? entry.fields.media.sys.id : null;
                            var durationInMinutes = (entry && entry.fields && entry.fields.durationInMinutes) ? entry.fields.durationInMinutes : null;
                            var title = (entry && entry.fields && entry.fields) ? entry.fields.title : null;
                            var imgAsset = (imgId && data.includes.Asset) ? data.includes.Asset.find(a => { return a.sys.id == imgId; }) : null
                            var mediaAsset = (mediaId && data.includes.Asset) ? data.includes.Asset.find(a => { return a.sys.id == mediaId; }) : null

                            var mediaItem = <MediaItem> {   
                                id: mediaItemId,
                                title: (title) ? title : item.fields.title,
                                category: (itemPr) ? this.wordSplit(itemPr.sys.id.replace('category', '')) : null,
                                subcategory: (itemSec) ? this.wordSplit(itemSec.sys.id) : null,
                                duration: (durationInMinutes != null) ? `${durationInMinutes} mins` : null,
                                img: (imgAsset) ? imgAsset.fields.file.url : null,
                                url: (mediaAsset) ? mediaAsset.fields.file.url : null
                            };
    
                            if (mediaItem.img && !mediaItem.img.startsWith(this.httpsProtocol)) {
                                mediaItem.img = this.httpsProtocol + mediaItem.img;
                            }
    
                            if (mediaItem.url && !mediaItem.url.startsWith(this.httpsProtocol)) {
                                mediaItem.url = this.httpsProtocol + mediaItem.url;
                                mediaItem.isVideo = this.getIsVideoItem(mediaAsset);
                            }
    
                            latestItem.mediaItem = mediaItem;
                        }
    
                        if (latestItem.isExternalLink && item.fields.externalLinkUrl) {
                            latestItem.externalLinkUrl = item.fields.externalLinkUrl; 
                            latestItem.externalLinkSubtitle = item.fields.externalLinkSubtitle;

                        }

                        if (!latestItems) { latestItems = []; }
                        latestItems.push(latestItem);
                    }
                }

                this._StorageService.setOfflineHomePageLatestItems(latestItems);
                resolve(latestItems);
            }).catch(e => {
                console.log(e);
                // Fallback to offline
                this._StorageService.getOfflineHomePageLatestItems().then(offlineitems => {
                    resolve(offlineitems ? offlineitems : null);
                });
            })
        });
    }

    getHomePageEventItems(): Promise<EventPreview[]> {
        return new Promise(resolve => {
            var eventItems: EventPreview[];

            this._http.get(this.getApiCallUrl(this._AppSettings.eventsContentPath), { headers: { Authorization: `Bearer ${this._AppSettings.authToken}` }}).toPromise().then(res => {
                var data: any = res;
                if (data && data.items && data.items.length > 0) {
                    
                    for (var i = 0; i < data.items.length; i++) {                        
                        var item = data.items[i];
                        var imgId = (item.fields.image) ? item.fields.image.sys.id : null;
                        var imageAsset = (imgId && data.includes && data.includes.Asset) ? 
                            data.includes.Asset.find(a => { 
                                return a.sys.id == imgId; 
                            }) : null;
    
                        var tag = (item.metadata && item.metadata.tags && item.metadata.tags.length > 0) ? 
                        item.metadata.tags[0].sys.id : null;
        
                        var eventItem: EventPreview = { 
                            img: (imageAsset) ? imageAsset.fields.file.url : null,
                            category: this.wordSplit(tag),
                            title: item.fields.title,
                            description: item.fields.description,
                            location: item.fields.location,
                            date: (item.fields.date) ? new Date(item.fields.date) : null
                        };
    
                        if (eventItem.img && !eventItem.img.startsWith(this.httpsProtocol)) {
                            eventItem.img = this.httpsProtocol + eventItem.img;
                        }
    
                        if (!eventItems) { eventItems = []; }
                        eventItems.push(eventItem);

                    }
                }
                
                this._StorageService.setOfflineHomePageEventItems(eventItems);
                resolve(eventItems);
            }).catch(e => {
                console.log(e);
                // Fallback to offline
                this._StorageService.getOfflineHomePageEventItems().then(offlineitems => {
                    resolve(offlineitems ? offlineitems : null);
                });
            });
        });
    }

    getCoursesPageModel(): Promise<CoursePageModel> {
        return new Promise(resolve => {            
            var model = <CoursePageModel> {
                courses: null
            };
            
            this._http.get(this.getApiCallUrl(this._AppSettings.coursesPath), { headers: { Authorization: `Bearer ${this._AppSettings.authToken}` }}).toPromise().then(res => {
                var data: any = res;
                if (data) {
                    var courses: Course[] = [];

                    try {
                        data.items.forEach(item => {
                            var imgCardId = item.fields.image.sys.id;
                            var imgPlayerId = item.fields.thumbnailImage.sys.id;
                            var introMediaItemId = (item.fields.introMediaItem) ? item.fields.introMediaItem.sys.id : null;

                            var mediaItemIds = [];
                            if (item.fields.mediaItems) {
                                item.fields.mediaItems.forEach(ci => { mediaItemIds.push(ci.sys.id); })
                            }
        
                            var imageCardAsset = data.includes.Asset.find(a => { return a.sys.id == imgCardId; });
                            var imagePlayerAsset = data.includes.Asset.find(a => { return a.sys.id == imgPlayerId; });
                            var introMediaItemAsset = (introMediaItemId != null) ? data.includes.Asset.find(a => { return a.sys.id == introMediaItemId; }) : null;
        
                            var course: Course = {
                                id: item.sys.id,
                                title: item.fields.name,
                                numberOfHours: item.fields.numberOfHours,
                                numberOfWeeks: item.fields.numberOfWeeks,
                                description: item.fields.description,
                                longDescription: item.fields.longDescription,
                                isLocked: !item.fields.isAvailable,
                                imgCardUrl: (imageCardAsset) ? imageCardAsset.fields.file.url : null,
                                imgPlayerUrl: (imagePlayerAsset) ? imageCardAsset.fields.file.url : null,
                                lessonGroups: [],
                                isComingSoon: item.fields.isComingSoon,
                                introMediaItemUrl: null,
                                courseWebLink: item.fields.courseWebLink
                            };

                            if (course.longDescription) {
                                var temp = course.longDescription;
                                temp = temp.replace(/\n{2}/g, '&nbsp;</p><p>');
                                temp = temp.replace(/\n/g, '&nbsp;<br />');
                                temp = '<p>' + temp + '</p>';
                                course.longDescription = temp;
                              }

                            // Override intro if youtube link 
                            if (introMediaItemAsset && introMediaItemAsset.fields.file) {
                                course.introMediaItemUrl = introMediaItemAsset.fields.file.url; 
                                course.introMediaItemIsVideo = this.getIsVideoItem(introMediaItemAsset);                            
                            }
                            else if (introMediaItemAsset && introMediaItemAsset.fields.overrideMediaUrl) {
                                course.introMediaItemUrl = introMediaItemAsset.fields.overrideMediaUrl;
                            }

                            // Fix links.
                            if (course.imgCardUrl && !course.imgCardUrl.startsWith(this.httpsProtocol)) {
                                course.imgCardUrl = this.httpsProtocol + course.imgCardUrl;
                            }
                            if (course.imgPlayerUrl && !course.imgPlayerUrl.startsWith(this.httpsProtocol)) {
                                course.imgPlayerUrl = this.httpsProtocol + course.imgPlayerUrl;
                            }  
                            if (course.introMediaItemUrl && !course.introMediaItemUrl.startsWith(this.httpsProtocol)) {
                                course.introMediaItemUrl = this.httpsProtocol + course.introMediaItemUrl;
                            }                  

                            mediaItemIds.forEach(ciId => {
                                var entry = data.includes.Entry.find(a => { return a.sys.id == ciId; })
                                if (entry) {     
                                    var lessonGroup = course.lessonGroups.find(lg => { return lg.title == entry.fields.description; })
                                    if (!lessonGroup) {  
                                        lessonGroup = {
                                            title: entry.fields.description,
                                            description: '',
                                            indexOrder: -1,
                                            items: []
                                        };
                                        course.lessonGroups.push(lessonGroup);
                                    }
                                    
                                    var category = (entry.metadata &&
                                        entry.metadata.tags && 
                                        entry.metadata.tags.length > 0) ?
                                        entry.metadata.tags[0].sys.id : null;
                                        
                                    var asset = (entry.fields.media) ? data.includes.Asset.find(a => { return a.sys.id == entry.fields.media.sys.id; }) : null;                   

                                    // don't render incomplete items.
                                    if (category && asset) {   
                                        var lesson = <MediaItem> {
                                            id: asset.sys.id,
                                            title: asset.fields.title,
                                            url: (asset.fields.file) ? asset.fields.file.url : "",
                                            img: course.imgPlayerUrl,
                                            duration: `${entry.fields.durationInMinutes} mins`,
                                            isCourseItem: true,
                                            category: this.wordSplit(category)
                                        };
                                        
                                        if (lesson.url && !lesson.url.startsWith(this.httpsProtocol)) {
                                            lesson.url = this.httpsProtocol + lesson.url;
                                        }
                                        
                                        lessonGroup.items.push(lesson);
                                    }
                                    else if (entry.fields.overrideMediaUrl) {
                                        var introLesson = <MediaItem> {
                                            isCourseIntroItem: true,
                                            id: entry.sys.id,
                                            title: entry.fields.title,
                                            url: entry.fields.overrideMediaUrl,
                                            img: course.imgPlayerUrl,
                                            duration: `${entry.fields.durationInMinutes} mins`,
                                            isCourseItem: true,
                                            category: null
                                        };
                                        
                                        lessonGroup.items.push(introLesson);                                        
                                    }

                                }
                            });
                               
                            courses.push(course);
                        });

                        model.courses = courses;
                        this._StorageService.setOfflineCoursesPageModel(model);
                    }
                    catch(e) {
                        console.log(e);
                    }                    
                }           
            
                resolve(model);
            }).catch((e) => {
                console.log(e);
                // Fallback to offline
                this._StorageService.getOfflineCoursesPageModel().then(offlineModel => {
                    resolve(offlineModel ? offlineModel : model);
                });
            });
        });
    }


    getLibraryPageModel(isNativePlatform: boolean): Promise<LibraryPageModel> {
        return new Promise(resolve => { 
            var model = <LibraryPageModel> {
                categorySlides: [],
                items: []
            };
            var tempCats = [];
            var tempSubcats = [];

            this._http.get(this.getApiCallUrl(this._AppSettings.libraryTopFiltersContentPath), { headers: { Authorization: `Bearer ${this._AppSettings.authToken}` }}).toPromise().then(res => {
                var data: any = res;
                if (data) {
                    var chunk: number;
                    tempCats = [
                        { id: -1, title: 'All', isAllCategory: true },
                        { id: -1, title: 'Downloads', isDownloadedCategory: true },
                        { id: -1, title: 'My ♡', isFavouriteCategory: true }
                    ];

                    // Don't show downloads on web version.
                    if (!isNativePlatform) {
                        tempCats.splice(1,1);
                    }

                    var list1 = [];
                    var list2 = [];
                    try {
                        // *** Swapped over tags for dedicated comma separated obj for catgeory buttons
                        // console.log(data);
                        var item = (data.items && data.items.length > 0) ? data.items[0] : null; 
                        if (item) {
                            var buttons = item.fields.buttons.split(',');
                            buttons.forEach(e => {
                                e = e.trim();
                                if (e) {
                                    tempCats.push(<Category> {
                                        id: '-1',
                                        title: e
                                    });
                                }
                            });
                        }
                        // *** Swapped over tags for dedicated comma separated obj for catgeory buttons


                        // *** Older way.
                        // data.items.forEach(item => {
                        //     console.log(item.name)
                        //     if (item.name.startsWith('category:')) {
                        //         tempCats.push(<Category> {
                        //             id: item.sys.id,
                        //             title: item.name.replace('category:', '').trim()
                        //         });
                        //     }
                        //     else {
                        //         tempSubcats.push(<Category> {
                        //             id: item.sys.id,
                        //             title: item.name
                        //         })
                        //     }
                        // });
                        // *** Older way.


                        // Hacky to make this always 2 rows if we've got uneven number
                        // of tags.
                        if (tempCats.length % 2 == 1) { 
                            tempCats.push({ id: -1, title: '', isHidden: true })
                        }
                        chunk = (tempCats.length / 2);
                        
                        if (tempCats.length > 0) {
                            list1 = tempCats.slice(0, chunk);
                            list2 = tempCats.slice(chunk, chunk + tempCats.length);
                        }

                        if (list1.length > 0 && list2.length > 0 && 
                            list1.length == list2.length) {
                                for (var i = 0; i < list1.length; i++) {
                                    model.categorySlides.push(<CategorySlide> { 
                                        category1: list1[i],
                                        category2: list2[i]
                                    });
                                }
                        }
                    }
                    catch(e) {
                        console.log(e);
                    }  
                }
            }).then(() => {
                this._http.get(this.getApiCallUrl(this._AppSettings.libraryContentPath), { headers: { Authorization: `Bearer ${this._AppSettings.authToken}` }}).toPromise().then(res => {
                    var data: any = res;
                    if (data) {
                        try {
                            // var primaryCat: Category;
                            // var secondaryCat: Category;
                            var newItem: MediaItem;
                            var imgAsset: any;
                            var fileAsset: any;

                            data.items.forEach(item => {
                                newItem = <MediaItem> {
                                    id: item.sys.id,
                                    title: item.fields.title,
                                    duration: `${item.fields.durationInMinutes} mins`                                    
                                }

                                imgAsset = data.includes.Asset.find(a => { return item.fields.image && a.sys.id == item.fields.image.sys.id; })
                                if (imgAsset) {
                                    newItem.img = imgAsset.fields.file.url
                                    if (newItem.img && !newItem.img.startsWith(this.httpsProtocol)) {
                                        newItem.img = this.httpsProtocol + newItem.img;
                                    }
                                }

                                fileAsset = data.includes.Asset.find(a => { return item.fields.media && a.sys.id == item.fields.media.sys.id; })
                                if (fileAsset) {
                                    newItem.url = fileAsset.fields.file.url
                                    if (newItem.url && !newItem.url.startsWith(this.httpsProtocol)) {
                                        newItem.url = this.httpsProtocol + newItem.url;
                                    }
                                    newItem.isVideo = this.getIsVideoItem(fileAsset);
                                }
                                
                                if (item.metadata && item.metadata.tags && item.metadata.tags.length > 0) {     
                                    // This prefix is added by Contentful, not user error.                                     
                                    var itemPr = item.metadata.tags.find(t => { return t.sys.id != 'library' && t.sys.id.startsWith('category'); })
                                    var itemSec = item.metadata.tags.find(t => { return t.sys.id != 'library' && !t.sys.id.startsWith('category'); })

                                    if (itemPr) {   
                                        // primaryCat = tempCats.find(c => { return c.name == itemPr.sys.id; })
                                        // newItem.category = (primaryCat) ? primaryCat.title : 'Not found';
                                        newItem.category = this.wordSplit(itemPr.sys.id.replace('category', ''));
                                    }
                                    else {
                                        newItem.category = 'No Category';
                                    }
                                    if (itemSec) {                                           
                                        // secondaryCat = tempSubcats.find(c => { return c.name == itemSec.sys.id; })
                                        // newItem.subcategory = (secondaryCat) ? secondaryCat.title : 'Not found';
                                        newItem.subcategory = this.wordSplit(itemSec.sys.id)
                                    }                                    
                                    else {
                                        newItem.subcategory = 'No Category';
                                    }
                                }

                                model.items.push(newItem);
                            });
                            this._StorageService.setOfflineLibraryPageModel(model);
                        }
                        catch(e) {
                            console.log(e);
                        }  
                    }
                    
                    resolve(model);
                }).catch(e => {
                    console.log(e);
                    // Fallback to offline
                    this._StorageService.getOfflineLibraryPageModel().then(offlineModel => {
                        resolve(offlineModel ? offlineModel : model);
                    });
                });
            }).catch(e => {
                console.log(e);
                // Fallback to offline
                this._StorageService.getOfflineLibraryPageModel().then(offlineModel => {
                    resolve(offlineModel ? offlineModel : model);
                });
            });
        });
    }

    
    getYouTubeItems(): Promise<YouTubeItem[]> {
        return new Promise(resolve => {
            var url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet`
            + `&playlistId=${this._AppSettings.youtubePlaylistId}`
            + `&maxResults=${this._AppSettings.youtubeFetchCount}`
            + `&key=${this._AppSettings.youtubeApiKey}`;
            
            this._http.get(url).toPromise().then(res => {
                var list: YouTubeItem[] = [];
                var data: any = res;
                data.items.forEach(item => {
                    list.push({
                        videoId: item.snippet.resourceId.videoId,
                        title: item.snippet.title,
                        thumbnail: item.snippet.thumbnails.medium.url,
                        publishedAt: format(new Date(item.snippet.publishedAt), "dd.MM.yyyy"), 
                        duration: '--:--'
                    });

                    // thumbs can be:
                     // default - 90 x 120
                     // high - 360 x 480
                     // max - 720 x 1280
                     // medium - 180 x 320
                     // standard - 480 x 640
                });

                // don't wait for this call to resolve, showing placeholder badge anyway.
                this.setYouTubeItemDurations(list); 
                resolve(list);
            }).catch(e => {
                console.log(e);
                resolve([]);
            });
        });
    }

    setYouTubeItemDurations(items: YouTubeItem[]): Promise<void> {
        return new Promise(resolve => {
            if (items.length == 0) { resolve(); }
            else {
                var ids = '';
                items.forEach(item => {
                    ids += item.videoId + ',';
                });
                ids = ids.substring(0, ids.length - 1);
                var url = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails`
                + `&id=${ids}`
                + `&key=${this._AppSettings.youtubeApiKey}`;
                
                this._http.get(url).toPromise().then(res => {
                    var matchingItem: YouTubeItem;
                    var data: any = res;
                    data.items.forEach(item => {
                        matchingItem = items.find(i => { return i.videoId == item.id; })
                        if (matchingItem) {
                            matchingItem.duration = this.youtubeDurationToUseful(item.contentDetails.duration) + ""
                        }
                    });
                    resolve();
                }).catch(e => {
                    console.log(e);
                    resolve();
                });
            }
        });
    }

    private youtubeDurationToUseful(duration) {
        var hours = 0;
        var minutes = 0;
        var seconds = 0;
    
        // Remove PT from string ref: https://developers.google.com/youtube/v3/docs/videos#contentDetails.duration
        duration = duration.replace('PT','');
    
        // If the string contains hours parse it and remove it from our duration string
        if (duration.indexOf('H') > -1) {
            var hours_split = duration.split('H');
            hours = parseInt(hours_split[0]);
            duration  = hours_split[1];
        }
    
        // If the string contains minutes parse it and remove it from our duration string
        if (duration.indexOf('M') > -1) {
            var minutes_split = duration.split('M');
            minutes = parseInt(minutes_split[0]);
            duration = minutes_split[1];
        }
    
        // If the string contains seconds parse it and remove it from our duration string
        if (duration.indexOf('S') > -1) {
            var seconds_split = duration.split('S');
            seconds = parseInt(seconds_split[0]);
        }
    
        var str = "";
        
        if (hours != 0) { str += hours + ":"; }

        if (minutes == 0) { str += "00" + ":"; }
        else if (minutes < 10) { str += "0" + minutes + ":"; }
        else if (minutes >= 10) { str += minutes + ":"; }
        else if (minutes == 0) { str += "00:"; }

        if (seconds > 0 && seconds < 10) { str += "0" + seconds; }
        else if (seconds < 10) { str += "0" + seconds; }
        else if (seconds >= 10) { str += seconds; }
        else if (seconds == 0) { str += "00"; }

        return str;
    }

    private getIsVideoItem(data: any) {
        try {
            if (data && data.fields && data.fields.file && data.fields.file.contentType) {
                return data.fields.file.contentType.startsWith('video/');
            }               
        }
        catch(e) {
        }        
        return false;
    }

    getYouTubeVideoIdFromString(str: string) {
        var marker1 = '/watch?v=';
        var marker2 = 'youtu.be/';
        var ytVId: string;
        if (str.indexOf(marker1) > -1) {
            ytVId = str.substring(str.indexOf(marker1) + marker1.length);
        }
        else if (str.indexOf(marker2) > -1) {
            ytVId = str.substring(str.indexOf(marker2) + marker2.length);
        }
        return ytVId;
    }

    private wordSplit(str: string) {
        if (str) {
            return str.replace(/([A-Z])/g, ' $1').trim();
        }
        return str;
    }
}
import { Injectable } from '@angular/core';

@Injectable()
export class AppSettingsService {
    
    public email: string = "aomapp@theartofmeditation.org";
    public finishedBackgroundUrl: string = '/assets/audioPlayer/finished-bg.jpg';
    public placeholderImageSrc: string = '/assets/placeholder.jpg';
    public androidDownloadFolder: string = 'AOMDownloads';
    public authToken: string = "TYH4mqRVT1dvFTHIzP0cIGeYk9KwWokToMB9iSkLEe8";
    public moreInfoSiteUrl: string = "https://theartofmeditation.org/about-aom/about-burgs/";
    public donateUrl: string = "https://www.paypal.com/donate?business=dhammacourses%40gmail.com&currency_code=GBP";
    public youtubeApiKey: string = "AIzaSyDjVqvGO1tE3HjPP3t-bliJxCGQGqCK_I0";
    public youtubeChannelId: string = "UCrNHCgKDm7TGu8Hp6AiqW4A";
    public youtubePlaylistId: string = "UUrNHCgKDm7TGu8Hp6AiqW4A";
    public youtubeFetchCount: number = 6;
    public contentfulAPIBaseUrl: string = "https://cdn.contentful.com/spaces/h2yb70ztzw7t/environments/master";
    public coursesPath: string = "/entries?content_type=courses";
    public libraryTopFiltersContentPath: string = "/entries?content_type=libraryTopFilters";
    // public libraryTagsPath: string = "/tags";
    public libraryContentPath: string = "/entries?content_type=libraryItem&metadata.tags.sys.id[in]=library";
    public featuredItemContentPath: string = "/entries?content_type=featuredItem";
    public latestItemsContentPath: string = "/entries?content_type=latestItem";
    public eventsContentPath: string = "/entries?content_type=event";
     
    constructor() {
    }
}
import { Injectable } from '@angular/core';
import { AOMUser, CompletedPlaybackItem, CoursePageModel, EventPreview, FeaturedItem, LatestItem, LibraryPageModel, MediaItem, NextLesson, UserNotifications } from '../interfaces/model';
import { Storage } from '@ionic/storage-angular'


@Injectable()
export class StorageService {

    private key_onboardingHasRun = "key_onboardingHasRun";
    private key_aomUser = "key_aomUser";
    private key_loggedInBefore = "key_loggedInBefore";
    private key_profilePhoto = "key_profilePhoto";
    private key_notificationReminder = "key_notificationReminder";
    private key_favouriteIds = "key_favouriteIds";
    private key_completedPlaybackHistory = "key_completedPlaybackHistory";
    private key_isAlreadyPlayedIds = "key_isAlreadyPlayedIds";
    private key_offlineCoursesJSON = "key_offlineCoursesJSON";
    private key_offlineLibraryJSON = "key_offlineLibraryJSON";
    private key_offlineHomeFeaturedItemJSON = "key_offlineHomeFeaturedItemJSON";
    private key_offlineHomeLatestItemsJSON = "key_offlineHomeLatestItemsJSON";
    private key_offlineHomeEventItemsJSON = "key_offlineHomeEventItemsJSON";
    private key_isDownloadedIds = "key_isDownloadedIds";
    private key_nextCourseLesson = "key_nextCourseLesson";


    constructor(    
        public _Storage: Storage    
    ) { }

    init(): Promise<boolean> {
        return new Promise(resolve => {
            this._Storage.create().then(() => {
                resolve(true);
            }).catch(e => {
                alert('Storage init: ' + JSON.stringify(e));
                resolve(false);
            });
        });
    }

    clearStorage(): Promise<void> {
        return new Promise(resolve => {            
            this.getLoggedInBefore().then(val => {
                this._Storage.clear().then(() => {
                    this.setLoggedInBefore(val).then(() => {
                        resolve();
                    });
                });
            })
        });
    }

    private internal_getIdFormatted(id: string) {
        return `{{${id}}}`;
    }







    // ************ AOMUser (logged in user) Methods ************

    getAOMUser(): Promise<AOMUser> {
        return new Promise(resolve => {
            this._Storage.get(this.key_aomUser).then(result => {
                if (result != null) {
                    resolve(<AOMUser>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });
    }

    setAOMUser(model: AOMUser): Promise<void> {
        return new Promise(resolve => {
            this._Storage.set(
                this.key_aomUser,
                JSON.stringify(model)
            ).then(() => {
                resolve();
            });
        });
    }







    // ************ LoggedInBefore Methods ************

    getLoggedInBefore(): Promise<boolean> {
        return new Promise(resolve => {
            this._Storage.get(this.key_loggedInBefore).then(result => {
                if (result != null) {
                    var hasRun = new Boolean(result).valueOf();
                    resolve(hasRun);
                }
                else {                    
                    resolve(false);
                }
            });
        });
    }

    setLoggedInBefore(value: boolean): Promise<void> {
        return new Promise(resolve => {
            if (value == null) {
                this._Storage.remove(
                    this.key_loggedInBefore
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_loggedInBefore,
                    value + ''
                ).then(() => {
                    resolve();
                });
            }
        });
    }







    // ************ Onboarding Methods ************

    getOnboardingHasRun(): Promise<boolean> {
        return new Promise(resolve => {
            this._Storage.get(this.key_onboardingHasRun).then(result => {
                if (result != null) {
                    var hasRun = new Boolean(result).valueOf();
                    resolve(hasRun);
                }
                else {                    
                    resolve(false);
                }
            });
        });
    }

    setOnboardingHasRun(value: boolean): Promise<void> {
        return new Promise(resolve => {
            if (value == null) {
                this._Storage.remove(
                    this.key_onboardingHasRun
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_onboardingHasRun,
                    value + ''
                ).then(() => {
                    resolve();
                });
            }
        });
    }







    // ************ Local Notifications Methods ************

    getUserNotifications(): Promise<UserNotifications> {
        return new Promise(resolve => {
            this._Storage.get(this.key_notificationReminder).then(result => {
                if (result != null) {
                    resolve(<UserNotifications>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });
    }

    setUserNotifications(model: UserNotifications): Promise<void> {
        return new Promise(resolve => {
            this._Storage.set(
                this.key_notificationReminder,
                JSON.stringify(model)
            ).then(() => {
                resolve();
            });
        });
    }








    // ************ Profile photo Methods ************

    getProfilePhoto(): Promise<string> {
        return new Promise(resolve => {
            this._Storage.get(this.key_profilePhoto).then(result => {
                if (result != null) {
                    resolve(result);
                }
                else {                    
                    resolve(null);
                }
            });
        });
    }

    setProfilePhoto(photo: string): Promise<void> {
        return new Promise(resolve => {
            if (photo == null) {
                this._Storage.remove(
                    this.key_profilePhoto
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_profilePhoto,
                    photo
                ).then(() => {
                    resolve();
                });
            }
        });
    }








    // ************ IsFavourite Methods ************

    getFavouritesString(): Promise<string> {
        return new Promise(resolve => {
            this._Storage.get(this.key_favouriteIds).then(result => {
                if (result != null) {
                    resolve(result);
                }
                else {                    
                    resolve('');
                }
            });
        });   
    }

    setFavouritesString(str: string): Promise<void> {
        return new Promise(resolve => {
            if (str == null) {
                this._Storage.remove(
                    this.key_favouriteIds
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_favouriteIds,
                    str
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getIsFavourite(id: string): Promise<boolean> {
        return new Promise(resolve => {
            this._Storage.get(this.key_favouriteIds).then(result => {
                if (result != null) {
                    id = this.internal_getIdFormatted(id);
                    resolve(result.indexOf(id) > -1);
                }
                else {                    
                    resolve(false);
                }
            });
        });   
    }

    getIsFavouriteWithMediaItemList(items: MediaItem[]): Promise<void> {
        return new Promise(resolve => {
            this.getFavouritesString().then(favouritesStr => {
                if (!favouritesStr) { 
                    favouritesStr = ''; 
                    items.forEach(i => {
                        i.isFavourite = false;
                    });
                    resolve();
                }
                else {   
                    var id = "";
                    items.forEach(i => {
                        id = this.internal_getIdFormatted(i.id);
                        i.isFavourite = favouritesStr.indexOf(id) > -1;
                    });
                    resolve();
                }
            });
        });   
    }

    addFavourite(id: string): Promise<void> {
        return new Promise(resolve => {
            if (id == null) {
                resolve();
            }
            else {
                this.getFavouritesString().then(favouritesStr => {
                    if (!favouritesStr) { favouritesStr = ''; }
                    id = this.internal_getIdFormatted(id);
                    if (favouritesStr.indexOf(id) == -1) {
                        favouritesStr += id;
                        this.setFavouritesString(favouritesStr).then(() => {
                            resolve();
                        });
                    }
                    else {
                        resolve();
                    }
                });
            }
        });
    }

    removeFavourite(id: string): Promise<void> {
        return new Promise(resolve => {
            if (id == null) {
                resolve();
            }
            else {
                this.getFavouritesString().then(favouritesStr => {
                    id = this.internal_getIdFormatted(id);
                    favouritesStr = favouritesStr.replace(id, '');
                    this.setFavouritesString(favouritesStr).then(() => {
                        resolve();
                    });
                });
            }
        });
    }








    // ************ Playback History Methods ************

    setCompletedPlaybackHistory(value: any): Promise<void> {
        return new Promise(resolve => {
            if (value == null) {
                this._Storage.remove(
                    this.key_completedPlaybackHistory
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_completedPlaybackHistory,
                    JSON.stringify(value)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getCompletedPlaybackHistory(): Promise<CompletedPlaybackItem[]>  {
        return new Promise(resolve => {
            this._Storage.get(this.key_completedPlaybackHistory).then(result => {
                if (result != null) {
                    resolve(<CompletedPlaybackItem[]>JSON.parse(result));
                }
                else {
                    resolve([]);
                }
            });
        });
    }








    // ************ IsAlreadyPlayed Methods ************

    setIsAlreadyPlayedString(str: string): Promise<void> {
        return new Promise(resolve => {
            if (str == null) {
                this._Storage.remove(
                    this.key_isAlreadyPlayedIds
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_isAlreadyPlayedIds,
                    str
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getIsAlreadyPlayedString(): Promise<string> {
        return new Promise(resolve => {
            this._Storage.get(this.key_isAlreadyPlayedIds).then(result => {
                if (result != null) {
                    resolve(result);
                }
                else {                    
                    resolve('');
                }
            });
        });   
    }

    getIsAlreadyPlayedWithMediaItemList(items: MediaItem[]): Promise<void> {
        return new Promise(resolve => {
            this.getIsAlreadyPlayedString().then(isAlreadyPlayedStr => {
                if (!isAlreadyPlayedStr) { 
                    isAlreadyPlayedStr = ''; 
                    items.forEach(i => {
                        i.isAlreadyPlayed = false;
                    });
                    resolve();
                }
                else {   
                    var id = "";
                    items.forEach(i => {
                        id = this.internal_getIdFormatted(i.id);
                        i.isAlreadyPlayed = isAlreadyPlayedStr.indexOf(id) > -1;
                    });
                    resolve();
                }
            });
        });   
    }

    addIsAlreadyPlayed(id: string): Promise<void> {
        return new Promise(resolve => {
            if (id == null) {
                resolve();
            }
            else {
                this.getIsAlreadyPlayedString().then(isAlreadyPlayedStr => {
                    if (!isAlreadyPlayedStr) { isAlreadyPlayedStr = ''; }
                    id = this.internal_getIdFormatted(id);
                    if (isAlreadyPlayedStr.indexOf(id) == -1) {
                        isAlreadyPlayedStr += id;
                        this.setIsAlreadyPlayedString(isAlreadyPlayedStr).then(() => {
                            resolve();
                        });
                    }
                    else {
                        resolve();
                    }
                });
            }
        });
    }







    // ************ OFFLINE Courses Methods ************

    setOfflineCoursesPageModel(val: any): Promise<void> {
        return new Promise(resolve => {
            if (val == null) {
                this._Storage.remove(
                    this.key_offlineCoursesJSON
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_offlineCoursesJSON,
                    JSON.stringify(val)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getOfflineCoursesPageModel(): Promise<CoursePageModel> {
        return new Promise(resolve => {
            this._Storage.get(this.key_offlineCoursesJSON).then(result => {
                if (result != null) {
                    resolve(<CoursePageModel>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });   
    }







    // ************ OFFLINE Library Methods ************

    setOfflineLibraryPageModel(val: any): Promise<void> {
        return new Promise(resolve => {
            if (val == null) {
                this._Storage.remove(
                    this.key_offlineLibraryJSON
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_offlineLibraryJSON,
                    JSON.stringify(val)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getOfflineLibraryPageModel(): Promise<LibraryPageModel> {
        return new Promise(resolve => {
            this._Storage.get(this.key_offlineLibraryJSON).then(result => {
                if (result != null) {
                    resolve(<LibraryPageModel>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });   
    }







    // ************ IsDownloaded Methods ************

    setIsDownloadedString(str: string): Promise<void> {
        return new Promise(resolve => {
            if (str == null) {
                this._Storage.remove(
                    this.key_isDownloadedIds
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_isDownloadedIds,
                    str
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getIsDownloadedString(): Promise<string> {
        return new Promise(resolve => {
            this._Storage.get(this.key_isDownloadedIds).then(result => {
                if (result != null) {
                    resolve(result);
                }
                else {                    
                    resolve('');
                }
            });
        });   
    }

    getIsDownloadedWithMediaItemList(items: MediaItem[]): Promise<void> {
        return new Promise(resolve => {
            this.getIsDownloadedString().then(isDownloadedStr => {
                if (!isDownloadedStr) { 
                    isDownloadedStr = ''; 
                    items.forEach(i => {
                        i.isDownloaded = false;
                    });
                    resolve();
                }
                else {   
                    var id = "";
                    items.forEach(i => {
                        id = this.internal_getIdFormatted(i.id);
                        i.isDownloaded = isDownloadedStr.indexOf(id) > -1;
                    });
                    resolve();
                }
            });
        });   
    }

    addIsDownloaded(id: string): Promise<void> {
        return new Promise(resolve => {
            if (id == null) {
                resolve();
            }
            else {
                this.getIsDownloadedString().then(isDownloadedStr => {
                    if (!isDownloadedStr) { isDownloadedStr = ''; }
                    id = this.internal_getIdFormatted(id);
                    if (isDownloadedStr.indexOf(id) == -1) {
                        isDownloadedStr += id;
                        this.setIsDownloadedString(isDownloadedStr).then(() => {
                            resolve();
                        });
                    }
                    else {
                        resolve();
                    }
                });
            }
        });
    }

    removeIsDownloaded(id: string): Promise<void> {
        return new Promise(resolve => {
            if (id == null) {
                resolve();
            }
            else {
                this.getIsDownloadedString().then(isDownloadedStr => {
                    id = this.internal_getIdFormatted(id);
                    isDownloadedStr = isDownloadedStr.replace(id, '');
                    this.setIsDownloadedString(isDownloadedStr).then(() => {
                        resolve();
                    });
                });
            }
        });
    }







    // ************ Next Course Lesson Methods ************

    setNextCourseLesson(obj: NextLesson): Promise<void> {
        return new Promise(resolve => {
            if (obj == null) {
                this._Storage.remove(
                    this.key_nextCourseLesson
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_nextCourseLesson,
                    JSON.stringify(obj)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getNextCourseLesson(): Promise<NextLesson> {
        return new Promise(resolve => {
            this._Storage.get(this.key_nextCourseLesson).then(result => {
                if (result != null) {
                    resolve(<NextLesson>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });   
    }







    // ************ OFFLINE HomeFeaturedItemJSON Methods ************

    setOfflineHomePageFeaturedItem(val: any): Promise<void> {
        return new Promise(resolve => {
            if (val == null) {
                this._Storage.remove(
                    this.key_offlineHomeLatestItemsJSON
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_offlineHomeLatestItemsJSON,
                    JSON.stringify(val)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getOfflineHomePageLatestItems(): Promise<LatestItem[]> {
        return new Promise(resolve => {
            this._Storage.get(this.key_offlineHomeLatestItemsJSON).then(result => {
                if (result != null) {
                    resolve(<LatestItem[]>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });   
    }







    // ************ OFFLINE HomeLatestItemsJSON Methods ************

    setOfflineHomePageLatestItems(val: any): Promise<void> {
        return new Promise(resolve => {
            if (val == null) {
                this._Storage.remove(
                    this.key_offlineHomeFeaturedItemJSON
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_offlineHomeFeaturedItemJSON,
                    JSON.stringify(val)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getOfflineHomePageFeaturedItem(): Promise<FeaturedItem> {
        return new Promise(resolve => {
            this._Storage.get(this.key_offlineHomeFeaturedItemJSON).then(result => {
                if (result != null) {
                    resolve(<FeaturedItem>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });   
    }







    // ************ OFFLINE HomeEventItemsJSON Methods ************

    setOfflineHomePageEventItems(val: any): Promise<void> {
        return new Promise(resolve => {
            if (val == null) {
                this._Storage.remove(
                    this.key_offlineHomeEventItemsJSON
                ).then(() => {
                    resolve();
                });
            }
            else {
                this._Storage.set(
                    this.key_offlineHomeEventItemsJSON,
                    JSON.stringify(val)
                ).then(() => {
                    resolve();
                });
            }
        });
    }

    getOfflineHomePageEventItems(): Promise<EventPreview[]> {
        return new Promise(resolve => {
            this._Storage.get(this.key_offlineHomeEventItemsJSON).then(result => {
                if (result != null) {
                    resolve(<EventPreview[]>JSON.parse(result));
                }
                else {                    
                    resolve(null);
                }
            });
        });   
    }    
}
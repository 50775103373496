import { Component, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { BioPreview, EventPreview, FeaturedItem, LatestItem, MediaItem, NextLesson, YouTubeItem } from '../interfaces/model';
import { AudioPlayerPage } from '../audioPlayer/audioPlayer.page';
import { APIService } from '../services/APIService';
import { YoutubePlayerPage } from '../youtubePlayer/youtubePlayer.page';
import { SplashScreen } from '@capacitor/splash-screen';
import { Events, EventsService } from '../services/EventsService';
import { AppSettingsService } from '../services/AppSettingsService';
import { StorageService } from '../services/StorageService';
import { VideoPlayerPage } from '../videoPlayer/videoPlayer.page';
import { format } from 'date-fns';
import { GenericPreviewPage } from '../genericPreview/genericPreview.page';

import * as videojs from 'video.js';
import { Observable } from 'rxjs';
import { LocalNotificationsService } from '../services/LocalNotificationsService';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  @ViewChild(IonContent) content: IonContent;
  public slideOptsYoutube: any;
  public slideOptsEvents: any;
  public featuredItem: FeaturedItem;
  public nextLesson: NextLesson;
  public latestItems: LatestItem[];
  public eventItems: EventPreview[];
  public youtubeItemsPair: any = [
    [{ videoId: '', duration: '', thumbnail: '', title: '', publishedAt: ' ' }, { videoId: '', duration: '', thumbnail: '', title: '', publishedAt: ' ' }], 
    [{ videoId: '', duration: '', thumbnail: '', title: '', publishedAt: ' ' }, { videoId: '', duration: '', thumbnail: '', title: '', publishedAt: ' ' }], 
    [{ videoId: '', duration: '', thumbnail: '', title: '', publishedAt: ' ' }, { videoId: '', duration: '', thumbnail: '', title: '', publishedAt: ' ' }]
  ];
  public isLoadingFeaturedItem: boolean = true;
  public isLoadingLatestContent: boolean = true;
  public isLoadingYoutubeItems: boolean = true;
  public isLoadingEventItems: boolean = true;
  public videoJS = videojs.default;
  public showLiveVideo: boolean = false;

  constructor(
    public _AppSettings: AppSettingsService,
    public _APIService: APIService,
    public _ModalController: ModalController,
    public _Events: EventsService,
    public _StorageService: StorageService,
    public _LocalNotificationsService: LocalNotificationsService
  ) {
    this.subscribeToEvents();

    this.slideOptsYoutube = {
      grabCursor: true,
      slidesPerView: 1.6,
      spaceBetween: 24,
      freeMode: true
    };

    this.slideOptsEvents = {
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 10  
    };

    SplashScreen.hide();
    
    this.loadData();
  }

  ionViewWillEnter() {
    this.content.scrollToTop(0);
  }

  loadData() {
    this._APIService.getHomePageModel().then(model => {
      this.featuredItem = (model && model.featuredItem) ? model.featuredItem : null;
      this.latestItems = (model && model.latestItems) ? model.latestItems : null;
      this.eventItems = (model && model.eventItems) ? model.eventItems : null;
      this.isLoadingFeaturedItem = false;
      this.isLoadingLatestContent = false;
      this.isLoadingEventItems = false;

      // if (this.featuredItem && this.featuredItem.isVideo) {
      //   this.loadFeaturedVideoInCard();
      // }
    });

    this.loadNextLesson();

    this._APIService.getYouTubeItems().then(list => {
      var newList = [];
      for (var i = 0; i < (list.length / 2); i++) {
        newList.push([list[i], list[i + 3]]);
      }
      this.youtubeItemsPair = newList;
      this.isLoadingYoutubeItems = false;
    });
  }

  loadNextLesson() {
    this._StorageService.getNextCourseLesson().then(nextLesson => {
      this.nextLesson = nextLesson;
    });
  }

  unsubscribeToEvents() {
    this._Events.unsubscribe(`${Events.ForceUpdatePage_}home`);
    this._Events.unsubscribe(Events.UpdateHomeNextLesson);
  }

  subscribeToEvents() {
    this.unsubscribeToEvents();

    this._Events.subscribe(`${Events.ForceUpdatePage_}home`, () => {
      this.isLoadingYoutubeItems = true;
      this.loadData();
    });

    this._Events.subscribe(Events.UpdateHomeNextLesson, () => {
      this.loadNextLesson();
    });
  }

  openNextLesson() {
    if (this.nextLesson.item.isCourseIntroItem) {
      this.openIntroYouTubePlayer(this.nextLesson.item);
    }
    else {
      this._ModalController.create({
        cssClass: 'full-screen-modal',
        component: AudioPlayerPage,
        componentProps: {
          subtitle: `${this.nextLesson.lessonGroupTitle}: ${this.nextLesson.courseTitle}`,
          item: this.nextLesson.item
        }
      }).then(player => {
        player.present();
      });
    }
  }

  openIntroYouTubePlayer(item: MediaItem) {
    var videoId: string = this._APIService.getYouTubeVideoIdFromString(item.url);
    if (!videoId) { 
      return this._LocalNotificationsService.showToast('Couldn\'t load YouTube video :('); 
    }
    
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: YoutubePlayerPage,
      componentProps: {
        item: <YouTubeItem> {
          videoId: videoId
        },
        mediaItemId: item.id,
        markViewedOnClose: true
      }
    }).then(player => {
      player.present();
    });
  }

  ionImgDidNotLoad(item: any) {
    item.img = this._AppSettings.placeholderImageSrc;
  }

  openYouTubePlayer(youtubeItem: YouTubeItem) {
    if (this.isLoadingYoutubeItems) { return; }

    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: YoutubePlayerPage,
      componentProps: {
        item: youtubeItem
      }
    }).then(player => {
      player.present();
    });
  }

  loadFeaturedItem() {
    if (!this.featuredItem) { return; }    

    if (this.featuredItem.isAudio) {
      this.openAudioPlayer(this.featuredItem.mediaItem);
    }
    else if (this.featuredItem.isVideo) {
      this.openVideoPlayer(this.featuredItem.mediaItem);
    }
    else if (this.featuredItem.isYouTubeLive) {
      this.openYouTubePlayer(<YouTubeItem> {
        videoId: this.featuredItem.youTubeVideoId
      });
    }
    else if (this.featuredItem.isBio) {
      this.openGenericPreview(<BioPreview> {
        img: this.featuredItem.img,
        category: this.featuredItem.category,
        title: this.featuredItem.title,
        description: this.featuredItem.description,
        date: this.featuredItem.date,
      });
    }
  }

  loadLatestItem(latestItem: LatestItem) {
    if (!latestItem) { return; }    

    if (latestItem.isAudio) {
      this.openAudioPlayer(latestItem.mediaItem);
    }
    else if (latestItem.isVideo) {
      this.openVideoPlayer(latestItem.mediaItem);
    }
    else if (latestItem.isExternalLink) {
      window.open(latestItem.externalLinkUrl, "_blank");
    }
  }

  openVideoPlayer(item: MediaItem) {
    // try {
    //   this.videoJS('featuredItemPlayer').pause();
    // }
    // catch(e) {
    // }

    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: VideoPlayerPage,
      componentProps: {
        item: item
      }
    }).then(player => {
      player.present();
    });
  }

  openAudioPlayer(item: MediaItem) {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: AudioPlayerPage,
      componentProps: {
        item: item
      }
    }).then(player => {
      player.present();
    });
  }

  openGenericPreview(item: BioPreview) {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: GenericPreviewPage,
      componentProps: {
        item: item
      }
    }).then(player => {
      player.present();
    });
  }

  getFormatDate(date: Date, formatStr: string) {
    return format(date, formatStr);
  }






  // loadFeaturedVideoInCard() {
  //   this.showLiveVideo = true;
  //   this.waitForElementToAppear('featuredItemPlayer').then(() => {
  //     this.videoJS('featuredItemPlayer', {
  //       controls: false,
  //       preload: 'auto',    
  //       muted: true    
  //     }).ready(() => {
  //       var player = this.videoJS('featuredItemPlayer');
        
  //       player.on('error', () => {          
  //         var msg = (player.error() && player.error().message) ?
  //           player.error().message : 'There was a problem playing the video sorry.';
  //         // this._LocalNotificationService.showAlert('Playback Error', msg);
  //       });

  //       setTimeout(() => {
  //         this.startPlayback()
  //       }, 250);
  //     });
  //   });
  // }

  // startPlayback() {
  //   this.videoJS('featuredItemPlayer').play().then(() => {
  //   });
  // }

  // waitForElementToAppear(elementId): Promise<any> {
  //   return new Observable(function (observer) {
  //     var el_ref;
  //     var f = () => {
  //       el_ref = document.getElementById(elementId);
  //       if (el_ref) {
  //         observer.next(el_ref);
  //         observer.complete();
  //         return;
  //       }
  //       window.requestAnimationFrame(f);
  //     };
  //     f();
  //   }).toPromise();
  // }

}

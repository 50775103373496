import '@capacitor/core';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { HomePage } from './home/home.page';
import { AudioPlayerPage } from './audioPlayer/audioPlayer.page';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { SettingsPage } from './settings/settings.page';
import { SettingsReminderPage } from './settingsReminder/settingsReminder.page';
import { CoursesPage } from './courses/courses.page';
import { ProfileComponent } from './components/profile/profile.component';
import { LibraryPage } from './library/library.page';
import { ChatPage } from './chat/chat.page';
import { DonatePage } from './donate/donate.page';
import { FormsModule } from '@angular/forms';
import { StorageService } from './services/StorageService';
import { EventsService } from './services/EventsService';
import { APIService } from './services/APIService';
import { LocalNotificationsService } from './services/LocalNotificationsService';
import { PayPalComponent } from './components/paypal/paypal.component';
import { HttpClientModule } from '@angular/common/http';
import { YoutubePlayerPage } from './youtubePlayer/youtubePlayer.page';
import { CoursePreviewPage } from './coursePreview/coursePreview.page';
import { OnboardingPage } from './onboarding/onboarding.page';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { NetworkComponent } from './components/network/network.component';
import { AppSettingsService } from './services/AppSettingsService';
import { PushService } from './services/PushService';
import { DownloadService } from './services/DownloadService';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ChatRoomPage } from './chatRoom/chatRoom.page';
import { PlaybackFinishedComponent } from './components/playback-finished/playback-finished.component';
import { VideoPlayerPage } from './videoPlayer/videoPlayer.page';
import { GenericPreviewPage } from './genericPreview/genericPreview.page';
import { BackButtonComponent } from './components/backbtn/backbtn.component';
import { CustomBadgeComponent } from './components/custom-badge/custom-badge.component';
import { GDPRPage } from './gdpr/gdpr.page';



const routes: Routes = [
  // { path: '', redirectTo: '/onboarding', pathMatch: 'full' },
  { path: 'audioPlayer', component: AudioPlayerPage },
  { path: 'youtubePlayer', component: YoutubePlayerPage },
  { path: 'onboarding', component: OnboardingPage },
  { path: 'home', component: HomePage },
  { path: 'courses', component: CoursesPage, data: { preload: true } },
  { path: 'coursePreview', component: CoursePreviewPage },
  { path: 'library', component: LibraryPage },
  { path: 'chat', component: ChatPage },
  { path: 'chatRoom', component: ChatRoomPage },
  { path: 'donate', component: DonatePage },
  { path: 'settings', component: SettingsPage },
  { path: 'settingsReminder', component: SettingsReminderPage }
];


@NgModule({
  declarations: [
    AppComponent,
    AudioPlayerPage,
    VideoPlayerPage,
    YoutubePlayerPage,
    OnboardingPage,
    HomePage,
    CoursesPage,
    CoursePreviewPage,
    LibraryPage,
    ChatPage,
    ChatRoomPage,
    DonatePage,
    SettingsPage,
    SettingsReminderPage,
    ProfileComponent,
    PayPalComponent,
    NetworkComponent,
    PlaybackFinishedComponent,
    GenericPreviewPage,
    BackButtonComponent,
    CustomBadgeComponent,
    GDPRPage
  ],
  entryComponents: [
    AudioPlayerPage,
    VideoPlayerPage,
    YoutubePlayerPage,
    OnboardingPage,
    HomePage,
    CoursesPage,
    CoursePreviewPage,
    LibraryPage,
    ChatPage,
    ChatRoomPage,
    DonatePage,
    SettingsPage,
    SettingsReminderPage,
    GenericPreviewPage,
    GDPRPage
  ],
  imports: [
    // AngularFireModule.initializeApp(environment.firebaseConfig, "angular-auth-firebase"),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    FormsModule,
    BackButtonDisableModule.forRoot(),
    BrowserModule, 
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({ mode: 'ios'}), 
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppSettingsService,
    PushService,
    StorageService,
    EventsService,
    APIService,
    LocalNotificationsService,
    DownloadService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonTabs, ModalController, NavController, PickerController, Platform, ToastController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { Events, EventsService } from './services/EventsService';
import { Capacitor } from '@capacitor/core';
import { StorageService } from './services/StorageService';
import { LocalNotificationsService } from './services/LocalNotificationsService';
import { DownloadService } from './services/DownloadService';
import { TabItem } from './interfaces/model';
import { PushService } from './services/PushService';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public isNativePlatform: boolean = true;
  public currentUrl: string = "";
  public showTabs: boolean = true;
  public tabItems: TabItem[] = [
    <TabItem> { name: 'home', onIcon: '/assets/tabs/tab-home.svg', offIcon: '/assets/tabs/tab-home-off.svg', isSelected: false },
    <TabItem> { name: 'courses', onIcon: '/assets/tabs/tab-courses.svg', offIcon: '/assets/tabs/tab-courses-off.svg', isSelected: false },
    <TabItem> { name: 'library', onIcon: '/assets/tabs/tab-library.svg', offIcon: '/assets/tabs/tab-library-off.svg', isSelected: false },
    <TabItem> { name: 'chat', onIcon: '/assets/tabs/tab-chat.svg', offIcon: '/assets/tabs/tab-chat-off.svg', isSelected: false },
    <TabItem> { name: 'donate', onIcon: '/assets/tabs/tab-donate.svg', offIcon: '/assets/tabs/tab-donate-off.svg', isSelected: false }
  ];

  constructor(
    public router: Router,
    public _Platform: Platform,
    public _Nav: NavController,
    public _ToastController: ToastController,
    public _Events: EventsService,
    public _AlertController: AlertController,
    public _PickerController: PickerController,
    public _ModalController: ModalController,
    public _StorageService: StorageService,
    public _LocalNotificationsService: LocalNotificationsService,
    public _DownloadService: DownloadService,
    public _Zone: NgZone,
    public _PushService: PushService
  ) {
    this._StorageService.init().then(() => {
      this.isNativePlatform = Capacitor.isNativePlatform();
      this.checkToIncludeDesktopStyles();
      if (Capacitor.getPlatform() == 'android') {
        _PushService.initPush();
      }
      this.customBackButton();
      this.registerWindowEvents();
      this.rescheduleNotifications();
      this.redirect();
    });
  }

  checkToIncludeDesktopStyles() {
    if (!this.isNativePlatform) {
      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = "/assets/desktop.css";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
  }

  setPage(slug: string): Promise<boolean> {    
    this.showTabs = (slug != 'onboarding');
    this.currentUrl = slug;
    
    if (this.showTabs) {
      this._Nav.navigateRoot(this.currentUrl).then(() => {
        this.styleTabs(); // workaround for weird bug on touchend event
      });
      this.styleTabs();
    }
    else {
      return this._Nav.navigateRoot(slug);
    }
  }

  styleTabs() {
    for (var i = 0; i < this.tabItems.length; i++) { 
      this._Zone.run(() => {
        this.tabItems[i].isSelected = (this.tabItems[i].name == this.currentUrl); 
      });
    }
  }

  redirect() { 
    this._StorageService.getOnboardingHasRun().then(onboardingHasRun => {
      if (onboardingHasRun) {
        this.setPage('home');
      }
      else {
        this.setPage('onboarding').then(() => {
          this._ModalController.getTop().then(el => {          
            if (el) {
              el.dismiss();
            }
          }); 
        });
      }
    });
  }

  isActiveSlug(slug: string) {
    return this.currentUrl == slug;
  }

  customBackButton() {
    App.addListener('backButton', () => {
      var elWasDismissed: boolean = false;
      var a = new Promise<void>(resolve => {
        return this._AlertController.getTop().then(el => {          
          if (el) {
            elWasDismissed = true;
            el.dismiss().then(() => {
              resolve();
            });
          }
          else {
            resolve();
          }
        }); 
      });

      var b = a.then(() => {
        return new Promise<void>(resolve => {
          if (elWasDismissed) { 
            resolve(); 
          }
          else {
            this._PickerController.getTop().then(el => {          
              if (el) {
                elWasDismissed = true;
                el.dismiss().then(() => {
                  resolve();
                });
              }
              else {
                resolve();
              }
            }); 
          }
        });
      });

      var c = b.then(() => {
        return new Promise<void>(resolve => {
          if (elWasDismissed) { 
            resolve(); 
          }
          else {
            this._ModalController.getTop().then(el => {          
              if (el) {
                elWasDismissed = true;
                el.dismiss().then(() => {
                  resolve();
                });
              }
              else {
                resolve();
              }
            }); 
          }
        });
      });
      
      c.then(() => {
        if (!elWasDismissed) {
          if (this.isActiveSlug('onboarding') || this.isActiveSlug('home')) {
            App.exitApp();
          }
          else {
            this.redirect();
          }
        }
      });

    });
  }
  
  registerWindowEvents() {
    // Note: Tried defining on actual page, but caused multiple fire bug.
    // Workaround is to define these once on app init so they don't fire multiple times.
    // Then pass the event on to the page using EventsService.
    window.addEventListener(Events.UpdateAppUI_WasPaused, () => {    
      this._Events.publish(Events.UpdateAppUI_WasPaused);  
    });

    window.addEventListener(Events.UpdateAppUI_WasPlayed, () => {      
      this._Events.publish(Events.UpdateAppUI_WasPlayed);
    });

    window.addEventListener(Events.DownloadMediaProgress, (data: any) => {
      if (data && data.id != null && data.progress != null) {
        this._DownloadService.updateDownloadProgress(data.id, data.progress);
      }
    });

    this._Events.subscribe(Events.Redirect, () => {
      this.redirect();
    });
  }

  rescheduleNotifications() {
    if (!Capacitor.isNativePlatform()) { return; }
    this._StorageService.getUserNotifications().then(model => {
      if (model && model.meditationReminderOn) {
        this._LocalNotificationsService.scheduleNotifications(model, false);
      }
    });  
  }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { AudioPlayerPage } from '../audioPlayer/audioPlayer.page';
import { Course, MediaItem, YouTubeItem } from '../interfaces/model';
import { APIService } from '../services/APIService';
import { VideoPlayerPage } from '../videoPlayer/videoPlayer.page';
import { YoutubePlayerPage } from '../youtubePlayer/youtubePlayer.page';


@Component({
  selector: 'app-coursePreview',
  templateUrl: 'coursePreview.page.html',
  styleUrls: ['coursePreview.page.scss'],
})
export class CoursePreviewPage implements OnInit {

  public bgImg: string;
  public title: string;
  public numberOfWeeksString: string;
  public longDescriptionHTML: string;
  public introMediaItemUrl: string;
  public introMediaItemIsVideo: boolean;

  constructor(
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _DomSanitizer: DomSanitizer,
    public _APIService: APIService
  ) {
  }

  ngOnInit() {
    var course: Course = this._Params.get('course');
    this.title = course.title;
    this.bgImg = course.imgPlayerUrl;
    this.numberOfWeeksString = (course.numberOfWeeks == 1) ? 
    `${course.numberOfWeeks} week` : `${course.numberOfWeeks} weeks`
    if (course.longDescription) {
      var temp = course.longDescription;
      temp = temp.replace(/\n{2}/g, '&nbsp;</p><p>');
      temp = temp.replace(/\n/g, '&nbsp;<br />');
      temp = '<p>' + temp + '</p>';
      this.longDescriptionHTML = temp;
    }
    this.introMediaItemUrl = course.introMediaItemUrl;
    this.introMediaItemIsVideo = course.introMediaItemIsVideo;
  }

  ngOnDestroy() {
  }

  close() {    
    this._ModalController.dismiss();
  }

  openIntroMediaItem() {
    if (!this.introMediaItemUrl) { return; }    

    var videoId: string = this._APIService.getYouTubeVideoIdFromString(this.introMediaItemUrl);

    if (videoId) {
      this._ModalController.create({
        cssClass: 'full-screen-modal',
        component: YoutubePlayerPage,
        componentProps: {
          item: <YouTubeItem> {
            videoId: videoId
          }
        }
      }).then(player => {
        player.present();
      });
    }
    else if (this.introMediaItemIsVideo) {
      this._ModalController.create({
        cssClass: 'full-screen-modal',
        component: VideoPlayerPage,
        componentProps: {
          item: <MediaItem> {
            url: this.introMediaItemUrl
          },
          introOnlyPlayerMode: true
        }
      }).then(player => {
        player.present();
      });
    }
    else {
      this._ModalController.create({
        cssClass: 'full-screen-modal',
        component: AudioPlayerPage,
        componentProps: {
          item: <MediaItem> {
            title: this.title,
            img: this.bgImg,
            url: this.introMediaItemUrl
          },
          introOnlyPlayerMode: true
        }
      }).then(player => {
        player.present();
      });
    }
  }

}

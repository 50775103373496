import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, ToastController } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { SettingsReminderPage } from '../settingsReminder/settingsReminder.page';
import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import { StorageService } from '../services/StorageService';
import { Events, EventsService } from '../services/EventsService';
import { APIService } from '../services/APIService';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppSettingsService } from '../services/AppSettingsService';
import { RemoteAudio } from 'capacitor-plugin-remote-audio';
import { GDPRPage } from '../gdpr/gdpr.page';


@Component({
  selector: 'app-settings',
  templateUrl: 'settings.page.html',
  styleUrls: ['settings.page.scss'],
})
export class SettingsPage implements OnInit {

  public photoSrc: string = '/assets/profile/avatar.png';
  public isNativePlatform: boolean = true;

  constructor(
    public _AppSettings: AppSettingsService,
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _LoadingController: LoadingController,
    public _ToastController: ToastController,
    public _StorageService: StorageService,
    public _Events: EventsService,
    public _APIService: APIService,
    public _AngularFireAuth: AngularFireAuth,
    public _AlertController: AlertController
  ) {
  }

  ngOnInit() {
    this.isNativePlatform = Capacitor.isNativePlatform();

    this._StorageService.getProfilePhoto().then(photo => {
      if (photo) {
        this.photoSrc = photo;
      }
    });
  }

  ngOnDestroy() {
  }

  close() {    
    this._ModalController.dismiss();
  }

  showSettingsNotifications() {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: SettingsReminderPage
    }).then(modal => {
      modal.present();
    });
  }

  async showShareApp() {
    var title: string = "The Art of Meditation";
    var url: string = "https://theartofmeditation.org/";
    var text: string = "It's good to share, be well.";

    if (!Capacitor.isNativePlatform()) {
      if (navigator.share) {
        navigator.share({
          title: title,
          text: text,
          url: url,
        }).catch((error) => console.log('Error sharing', error));
        return;
      }
      this._ToastController.create({
        message: 'Coming soon...',
        position: 'middle',
        duration: 1500
      }).then(toast => {
        toast.present();
      });
      return;
    }

    // BUG: Capacitor breaks if this is invoked twice in a row.
    await Share.share({
      title: title,
      url: url,
      text: text,
      dialogTitle: title
    });
  }

  takePhoto() {
    Camera.getPhoto({
      quality: 70,
      allowEditing: false,
      resultType: CameraResultType.Base64
    }).then(result => {
      this.photoSrc = 'data:image/jpeg;base64,' + result.base64String;
      this._StorageService.setProfilePhoto(this.photoSrc).then(() => {
        this._Events.publish(Events.ProfilePhotoUpdated);
      });
    });
  }

  async confirmLogout() {
    var alert = await this._AlertController.create({
      mode: 'md',
      animated: true,
      header: 'Confirm Logout',
      message: Capacitor.isNativePlatform() ? 'This will also delete any downloaded items. Are you sure?' : 'Are you sure?',
      buttons: [{ 
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
        }
      }, {
        text: 'Logout',
        handler: () => {
          RemoteAudio.deleteAllDownloads({ folderName: this._AppSettings.androidDownloadFolder });                        
          this._AngularFireAuth.signOut().catch(e => {});
          this._StorageService.clearStorage().then(() => {
            this._Events.publish(Events.Redirect);
          });
        }
      }]
    });
    await alert.present();    
  }

  showTandCs() {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: GDPRPage
    }).then(modal => {
      modal.present();
    });
  }

  showRateApp() {    
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    RemoteAudio.showRateApp();
  }
  
}

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-backbtn',
  templateUrl: './backbtn.component.html',
  styleUrls: ['./backbtn.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Input() isForwardBtn: boolean;
  @Input() isDark: boolean;

  constructor(
    public _ModalController: ModalController
  ) { 
    
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}

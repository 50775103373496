/*** Ionic Modal Enter Animation IN and OUT slideX ***/

import { createAnimation, Animation } from '@ionic/core';

const speed: number = 350;

export function customPageAnimation_IN(rootElement: HTMLElement): Animation {
  const backdropAnimation = createAnimation()
    .addElement(rootElement.querySelector('ion-backdrop')!)
    .fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

  const wrapperAnimation = createAnimation()
    .addElement(rootElement.querySelector('.modal-wrapper')!)
    .fromTo('transform', 'translateX(100vh)', 'translateX(0vh)');

  return createAnimation()
    .addElement(rootElement)
    .easing('ease-out')
    .duration(speed)
    .beforeAddClass('show-modal')
    .addAnimation([backdropAnimation, wrapperAnimation]
    );
}

export function customPageAnimation_OUT(rootElement: HTMLElement): Animation {
  const backdropAnimation = createAnimation()
    .addElement(rootElement.querySelector('ion-backdrop')!)
    .fromTo('opacity', 'var(--backdrop-opacity)', '0.01');

  const wrapperAnimation = createAnimation()
    .addElement(rootElement.querySelector('.modal-wrapper')!)
    .fromTo('transform', 'translateX(0vh)', 'translateX(100vh)');

  return createAnimation()
    .addElement(rootElement)
    .easing('ease-out')
    .duration(speed)
    .addAnimation([backdropAnimation, wrapperAnimation]
    );
}
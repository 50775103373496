import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-badge',
  templateUrl: './custom-badge.component.html',
  styleUrls: ['./custom-badge.component.scss'],
})
export class CustomBadgeComponent implements OnInit {

  @Input() text: string;
  @Input() isInverted: boolean;
  @Input() isDuration: boolean;

  public extraClasses: string = "";

  constructor(
  ) { 
  }

  ngOnInit() {
    if (this.isInverted) {
      this.extraClasses += ' inverted';
    }  
    if (this.isDuration) {
      this.extraClasses += ' duration';
    }  
  }

  ngOnDestroy() {
  }
}

import { Injectable, NgZone } from '@angular/core';
import { RemoteAudio } from 'capacitor-plugin-remote-audio';
import { MediaItem } from '../interfaces/model';
import { AppSettingsService } from './AppSettingsService';
import { Events, EventsService } from './EventsService';
import { LocalNotificationsService } from './LocalNotificationsService';
import { StorageService } from './StorageService';


@Injectable()
export class DownloadService {
    private downloadList: MediaItem[] = [];

    constructor(
        public _AppSettings: AppSettingsService,
        public _LocalNotificationsService: LocalNotificationsService,
        public _StorageService: StorageService,
        public _Zone: NgZone,
        public _Events: EventsService
    ) {
    }  

    toggleDownload(item: MediaItem) {
        if (!item) { return; }
        
        if (!item.isDownloaded && !item.isDownloading) {
            this.downloadItem(item);
        }
        else if (item.isDownloaded) {
            this.confirmDeleteFile(item);
        }
        else if (!item.isDownloaded && item.isDownloading) {
            if (this.downloadList.find(i => { return i.id == item.id; })) {
                this.confirmStopDownload(item);                            
            }
            else {
                this.confirmDeleteFile(item);
            }
        }
    }

    downloadItem(item: MediaItem) {
        RemoteAudio.checkOrRequestStoragePermissions().then(data => {
            if (data && data.hasStoragePermissions) {  
                RemoteAudio.downloadMedia({ 
                    id: item.id, 
                    url: item.url, 
                    folderName: this._AppSettings.androidDownloadFolder
                }).then(() => {
                    item.downloadProgress = 0;
                    item.isDownloading = true;
                    this.downloadList.push(item);
                });
            }
            else {
                this._LocalNotificationsService.showAlert(
                    'Permission', 
                    'Please enable storage persmissions to download media, then try again.'
                );
            }
        });
    }

    confirmDeleteFile(item: MediaItem) {
        this._LocalNotificationsService.showConfirmYesNo(
            'Delete Download?', 
            'This will delete it from your device.', 
            'Delete',
            () => {
                RemoteAudio.deleteDownload({ 
                    id: item.id, 
                    folderName: this._AppSettings.androidDownloadFolder 
                }).then(() => {
                    item.isDownloaded = false;
                    item.isDownloading = false;
                    item.downloadProgress = null;
                    this._StorageService.removeIsDownloaded(item.id).then(() => {
                        this._Events.publish(Events.ItemIsDownloadedUpdated_Library, item);
                    });
                });
            }
        );  
    }

    confirmStopDownload(item: MediaItem) {
        this._LocalNotificationsService.showConfirmYesNo(
            'Stop Download?', 
            'This will stop the current download and delete it from your device.', 
            'Stop',
            () => {
                RemoteAudio.cancelDownload({ 
                    id: item.id, 
                    folderName: this._AppSettings.androidDownloadFolder 
                }).then(() => {
                    item.isDownloaded = false;
                    item.isDownloading = false;
                    item.downloadProgress = null;
                    this._StorageService.removeIsDownloaded(item.id).then(() => {
                        this._Events.publish(Events.ItemIsDownloadedUpdated_Library, item);
                    });
                });
            }
        );
    }

    updateDownloadProgress(id: string, progress: number) {
        this._Zone.run(() => {        
          var item = this.downloadList.find(i => { return i.id == id; })
          if (item) {
            item.downloadProgress = progress;
            
            if (progress == 100) {
                item.isDownloading = false;
                item.isDownloaded = true;
                this._StorageService.addIsDownloaded(item.id).then(() => {
                    this.downloadList = this.downloadList.filter(i => { return i.id != id; })
                    this._Events.publish(Events.ItemIsDownloadedUpdated_Library, item);
                });
            }
          }
        });
    }
}
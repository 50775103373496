import { Component } from '@angular/core';
import { Events, EventsService } from '../services/EventsService';
import { StorageService } from '../services/StorageService';
import { AngularFireAuth } from '@angular/fire/auth';
import { LocalNotificationsService } from '../services/LocalNotificationsService';
import { SplashScreen } from '@capacitor/splash-screen';
import { AOMUser } from '../interfaces/model';
import firebase from 'firebase/app';
import { APIService } from '../services/APIService';
import { ModalController } from '@ionic/angular';
import { GDPRPage } from '../gdpr/gdpr.page';

@Component({
  selector: 'app-onboarding',
  templateUrl: 'onboarding.page.html',
  styleUrls: ['onboarding.page.scss'],
})
export class OnboardingPage {

  public name: string;
  public email: string;
  public password: string;
  public showStep: number;
  public animation: string;
  public loginTitle: string;
  public isLoading: boolean;
  public hasLoggedInBefore: boolean;

  constructor(
    public _StorageService: StorageService,
    public _Events: EventsService,
    public _AngularFireAuth: AngularFireAuth,
    public _LocalNotificationsService: LocalNotificationsService,
    public _APIService: APIService,
    public _ModalController: ModalController
  ) {    
  }

  ionViewDidEnter() {  
    this._StorageService.getLoggedInBefore().then(loggedInBefore => {
      this.hasLoggedInBefore = loggedInBefore;
      this.animation = "animate__fadeIn"; 
      this.showStep = (loggedInBefore) ? 4 : 1;   
      if (this.showStep == 1) {
        this.resetForm();
      }   
    });
    SplashScreen.hide();
  }

  switchLoginView() {
    this.animation = "animate__fadeOut";
    setTimeout(() => {
      this.hasLoggedInBefore = !this.hasLoggedInBefore;
      this.animation = "animate__fadeIn"; 
      this.showStep = (this.hasLoggedInBefore) ? 4 : 1;
      if (this.showStep == 1) {
        this.resetForm();        
      }     
    }, 1500);
  }

  resetForm() {
    this.name = null;
    this.email = null;
    this.password = null;
    this._StorageService.setLoggedInBefore(null);
  }

  step1() {
    this.animation = "animate__fadeOut";
    setTimeout(() => {
      this.animation = "animate__fadeIn"; 
      this.showStep = 1;
    }, 1500);
  }

  step2() {
    if (!this.name) { return; }
    this.animation = "animate__fadeOut"; 

    setTimeout(() => {
      this.animation = "animate__fadeIn"; 
      this.showStep = 2;

      setTimeout(() => {
        this.animation = "animate__fadeOut"; 

        setTimeout(() => {
          this.animation = "animate__fadeIn"; 
          this.showStep = 3;
        }, 1500);

      }, 3000);

    }, 1500);
  }

  step4() {
    this.animation = "animate__fadeOut"; 
    setTimeout(() => {
      this.animation = "animate__fadeIn"; 
      this.showStep = 4;
    }, 1500);
  }

  loginOrRegister() {
    if (!this.email || !this.password) { return; }

    this.isLoading = true;

    // If we need sign in with google later use:
    // return this._AngularFireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider());

    if (this.hasLoggedInBefore) {
      this._AngularFireAuth.signInWithEmailAndPassword(this.email, this.password).then(data => {
        this.goHome(data);
      }).catch(e => {
        this.showFirebaseError(e);
      });
    }
    else {
      this._AngularFireAuth.createUserWithEmailAndPassword(this.email, this.password).then(data => {
        this.goHome(data);
      }).catch(e => {
        this.showFirebaseError(e);
      });
    }
  }

  goHome(data: any) {
    var aomUser: AOMUser = {
      name: this.name,
      uid: data.user.uid,
      displayName: data.user.displayName,
      photoUrl: data.user.photoUrl,
      refreshToken: data.user.refreshToken
    };
    this._StorageService.setAOMUser(aomUser);
    this._StorageService.setOnboardingHasRun(true).then(() => {
      this._Events.publish(Events.Redirect);
    });
  }

  showFirebaseError(e) {
    if (e && e.message) {
      this._LocalNotificationsService.showToast(e.message).then(() =>{
        this.isLoading = false;
      });
    }
  }

  // signInWithGoogle() {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   this._AngularFireAuth.signInWithPopup(provider).then(data => {
  //     if (data && data.user) {
  //       this.goHome(data);
  //     }
  //     else {
  //       this._LocalNotificationsService.showToast('Unknown error signing in with Google');  
  //     }
  //   }).catch(e => {
  //     this._LocalNotificationsService.showAlert(e.code, e.message)
  //   })
  // }

  showTandCs() {
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: GDPRPage
    }).then(modal => {
      modal.present();
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, NavParams } from '@ionic/angular';
import { format } from 'date-fns';


@Component({
  selector: 'app-genericPreview',
  templateUrl: 'genericPreview.page.html',
  styleUrls: ['genericPreview.page.scss'],
})
export class GenericPreviewPage implements OnInit {

  public bgImg: string;
  public contentItem: any;
  public linkifiedDescription: string;

  constructor(
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _DomSanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    var item: any = this._Params.get('item');
    this.bgImg = item.img
    this.contentItem = item;
    this.linkifiedDescription = this.urlify(item.description);    
  }

  ngOnDestroy() {
  }

  close() {    
    this._ModalController.dismiss();
  }

  getFormatDate(date: Date, formatStr: string) {
    return format(date, formatStr);
  }
 
  urlify(text: string) {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, (url) => {
      return `<a target="_blank" href="${url}">${url}</a>`;
    });
  }
}

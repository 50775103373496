import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavParams } from '@ionic/angular';
import { StorageService } from '../services/StorageService';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppSettingsService } from '../services/AppSettingsService';
import { AOMUser, ChatMessage, ChatRoom } from '../interfaces/model';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocalNotificationsService } from '../services/LocalNotificationsService';
import { format } from 'date-fns';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-chatRoom',
  templateUrl: 'chatRoom.page.html',
  styleUrls: ['chatRoom.page.scss'],
})
export class ChatRoomPage implements OnInit {

  @ViewChild(IonContent) content: IonContent;

  public chatRoom: ChatRoom;
  public newMsg: string;
  public inputDisabled: boolean;
  public myAOMUser: AOMUser;
  public isLoadingData: boolean = true;
  public watcher: Observable<any>;
  public messages: ChatMessage[];
  public formatStr: string = "do MMM @ H:mm";

  constructor(
    public _AppSettings: AppSettingsService,
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _StorageService: StorageService,
    public _LocalNotificationsService: LocalNotificationsService,
    public _AngularFirestore: AngularFirestore,
    public _AngularFireAuth: AngularFireAuth
  ) {
    this.myAOMUser = this._Params.get('user');
    this.chatRoom = this._Params.get('chatRoom');

    if (!this.myAOMUser) {
      this.close().then(() => {
        this._LocalNotificationsService.showToast('Please re-login to use chat.');
      });
    }
    else {
      this.loadData();
    }

  }

  async loadData() {
    await this._AngularFireAuth.signInAnonymously();

    this.watcher = this._AngularFirestore
      .collection('chatrooms')
      .doc(this.chatRoom.firebaseCollectionId)
      .collection('messages', ref => ref.orderBy('timeStamp')).snapshotChanges();

    this.watcher.subscribe(res => {
      this.messages = res.map(e => {
        var data = e.payload.doc.data();
        return <ChatMessage> {
          id: e.payload.doc.id,
          humanDate: format(data.timeStamp.toDate(), this.formatStr),
          name: data.name,
          message: data.message,
          user: data.user,
          isMe: this.isMe(data.user)
        }
      });
      this.content.scrollToBottom();  
    });

    this.applyKeyboardStayOpenFix();    
    this.isLoadingData = false;         
  }

  isMe(userId: string) {
    if (!userId) { return false; }
    return userId == this.myAOMUser.uid;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  close() {    
    return this._ModalController.dismiss();
  }
  
  sendMessage() {
    if (!this.newMsg || this.newMsg.trim().length == 0) { return; }
    this._AngularFirestore
      .collection('chatrooms')
      .doc(this.chatRoom.firebaseCollectionId)
      .collection('messages').add({
        message: this.newMsg,
        user: this.myAOMUser.uid,
        timeStamp: new Date(),
        name: this.myAOMUser.name
      })
      .then(response => { 
      }, error => {
        console.log(error)
      });

    this.newMsg = null;
  }

  applyKeyboardStayOpenFix() {
    let el = document.getElementById('btnSend');
    if (!el) { return; }

    el.addEventListener('click', (e) => { this.stopBubble(e); });
    el.addEventListener('mousedown', (e) => { this.stopBubble(e); });
    el.addEventListener('touchdown', (e) => { this.stopBubble(e); });
    el.addEventListener('touchmove', (e) => { this.stopBubble(e); });
    el.addEventListener('touchstart', (e) => { this.stopBubble(e); });
    
    //Triggered by a phone
    el.addEventListener('touchend', (e) => { this.stopBubble(e); this.sendMessage(); });
    //Triggered by the browser
    el.addEventListener('mouseup', (event) => { this.sendMessage(); });
  }
  stopBubble(event) {
    event.preventDefault(); 
    event.stopPropagation(); //Stops event bubbling
  }
}

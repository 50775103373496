import { Component, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { AppSettingsService } from '../services/AppSettingsService';

@Component({
  selector: 'app-donate',
  templateUrl: 'donate.page.html',
  styleUrls: ['donate.page.scss'],
})
export class DonatePage {

  @ViewChild(IonContent) content: IonContent;

  public tabIndex: number = 0;

  constructor(
    public _AppSettings: AppSettingsService,
    public _ModalController: ModalController
  ) {

  }

  ionViewWillEnter() {
    this.content.scrollToTop(0);
  }

  openSite() {
    window.open(this._AppSettings.moreInfoSiteUrl, "_blank");
  }
}

import { Component, ViewChild } from '@angular/core';
import { IonContent, IonSlides, ModalController } from '@ionic/angular';
import { AudioPlayerPage } from '../audioPlayer/audioPlayer.page';
import { CoursePreviewPage } from '../coursePreview/coursePreview.page';
import { Course, MediaItem, NextLesson, YouTubeItem } from '../interfaces/model';
import { APIService } from '../services/APIService';
import { Events, EventsService } from '../services/EventsService';
import { StorageService } from '../services/StorageService';
import { LocalNotificationsService } from '../services/LocalNotificationsService';
import { AppSettingsService } from '../services/AppSettingsService';
import { DownloadService } from '../services/DownloadService';
import { YoutubePlayerPage } from '../youtubePlayer/youtubePlayer.page';
import { Capacitor } from '@capacitor/core';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-courses',
  templateUrl: 'courses.page.html',
  styleUrls: ['courses.page.scss'],
})
export class CoursesPage {

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonSlides) ionSlides: IonSlides;

  public activeIndex: number = 0;
  public slideOpts: any;
  public courses: Course[] = [];
  public selectedCourse: Course;
  public totalCompleted: number = 0;
  public isLoadingCourses: boolean = true;
  public isNativePlatform: boolean = true;

  constructor(
    public _AppSettings: AppSettingsService,
    public _APIService: APIService,
    public _ModalController: ModalController,
    public _StorageService: StorageService,
    public _Events: EventsService,
    public _LocalNotificationsService: LocalNotificationsService,
    public _DownloadService: DownloadService,
    public _DomSanitizer: DomSanitizer
  ) {
    this.subscribeToEvents();

    this.isNativePlatform = Capacitor.isNativePlatform();

    this.slideOpts = {
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 10  
    };

    this.loadData();
  }

  ionViewWillEnter() {
    this.content.scrollToTop(0);
  }

  loadData() {
    this.activeIndex = 0;

    this._APIService.getCoursesPageModel().then(model => {      
      this.courses = (model && model.courses) ? model.courses : null;

      this.courses.forEach(c => {
        console.log(c.courseWebLink);
      });

      if (this.courses && this.courses.length > 0) {

        // Collect items for easier processing.
        var allItemsTemp: any[] = [];
        var totalCourseItems: number;
        this.courses.forEach(course => {
          totalCourseItems = 0;
          if (!course.lessonGroups) { course.lessonGroups = [] }
          course.lessonGroups.forEach(group => {
            totalCourseItems += group.items.length;
            allItemsTemp = allItemsTemp.concat(group.items);
          });
          course.totalItems = totalCourseItems;
        });

        this._StorageService.getIsFavouriteWithMediaItemList(allItemsTemp);
        this._StorageService.getIsDownloadedWithMediaItemList(allItemsTemp);
        this._StorageService.getIsAlreadyPlayedWithMediaItemList(allItemsTemp).then(() =>{
          this.setTotalCompletedCount();
          this.setNextCourseLesson();
        });
        
        this.selectedCourse = (this.courses && this.courses.length > 0) ? this.courses[this.activeIndex] : null;
      }

      this.isLoadingCourses = false;
    });
  }

  unsubscribeToEvents() {
    this._Events.unsubscribe(Events.ItemIsFavouriteUpdated_Courses);
    this._Events.unsubscribe(Events.ItemIsAlreadyPlayedUpdated_Courses);
    this._Events.unsubscribe(`${Events.ForceUpdatePage_}courses`);
  }

  subscribeToEvents() {
    this.unsubscribeToEvents();

    this._Events.subscribe(`${Events.ForceUpdatePage_}courses`, () => {
      this.isLoadingCourses = true;
      this.loadData();
    });

    this._Events.subscribe(Events.ItemIsFavouriteUpdated_Courses, (updatedItem: MediaItem) => {
      if (!updatedItem) { return; }
      var thisItem = this.findMediaItemInCourses(updatedItem);
      if (!thisItem) { return; }
      thisItem.isFavourite = updatedItem.isFavourite;
    });

    this._Events.subscribe(Events.ItemIsAlreadyPlayedUpdated_Courses, (updatedItem: MediaItem) => {
      if (!updatedItem) { return; }
      var thisItem = this.findMediaItemInCourses(updatedItem);
      if (!thisItem) { return; }
      thisItem.isAlreadyPlayed = updatedItem.isAlreadyPlayed;
      this.setTotalCompletedCount();
      this.setNextCourseLesson();
    });


  }

  findMediaItemInCourses(updatedItem: MediaItem) {
    if (!this.courses) { return null; }

    for (let course of this.courses) {
      if (!course.lessonGroups) { course.lessonGroups = [] }
      for (let group of course.lessonGroups) {
        var thisItem = group.items.find(i => { return i.id == updatedItem.id; })
        if (thisItem) {
          thisItem.isAlreadyPlayed = updatedItem.isAlreadyPlayed;
          return thisItem;
        }
      }
    }  
    return null; 
  }

  setTotalCompletedCount() {
    if (!this.courses) { return null; }
    var totalCompleted: number;
    this.courses.forEach(course => {
      totalCompleted = 0;
      if (!course.lessonGroups) { course.lessonGroups = [] }
      course.lessonGroups.forEach(group => {
        totalCompleted += group.items.filter(i => { return i.isAlreadyPlayed; }).length;
      });
      course.totalCompleted = totalCompleted;
    });
  }

  setNextCourseLesson() {
    var courseTitle: string;
    var groupTitle: string;
    var notPlayedYetItem: MediaItem;

    for(var i = 0; i < this.courses.length; i++) {
      if (!this.courses[i].lessonGroups) { continue; }

      for(var j = 0; j < this.courses[i].lessonGroups.length; j++) {
        if (notPlayedYetItem) { break; }

        if (!this.courses[i].lessonGroups[j].items) { continue; }
        notPlayedYetItem = this.courses[i].lessonGroups[j].items.find(i => {
          return !i.isAlreadyPlayed;
        });

        if (notPlayedYetItem) {
          courseTitle = this.courses[i].title;
          groupTitle = this.courses[i].lessonGroups[j].title;
          break;
        }
      }
    }
    
    // Update Home page next lesson      
    var a = new Promise<void>(resolve => {
      if (notPlayedYetItem) {
        var nextLesson: NextLesson = {
          courseTitle: courseTitle,
          lessonGroupTitle: groupTitle,
          item: notPlayedYetItem
        };
        this._StorageService.setNextCourseLesson(nextLesson).then(() => {
          resolve();
        });
      }
      else {
        this._StorageService.setNextCourseLesson(null).then(() => {
          resolve();
        });
      }
    });
    a.then(() => {
      this._Events.publish(Events.UpdateHomeNextLesson);
    });
  }

  ionImgDidNotLoad(course) {
    course.imgCardUrl = this._AppSettings.placeholderImageSrc;
  }

  ionSlideWillChange() {
    if (this.isLoadingCourses) { return; }
    this.ionSlides.getActiveIndex().then(index => {
      this.activeIndex = index;
    });
  }

  ionSlideDidChange() {
    if (this.isLoadingCourses) { return; }
    this.selectedCourse = this.courses[this.activeIndex];
  }

  toggleFavourite(item: MediaItem, event: Event) {
    if (event) {
      event.stopPropagation();
    }
    item.isFavourite = !item.isFavourite;
    if (item.isFavourite) {
      this._StorageService.addFavourite(item.id);
    }
    else {
      this._StorageService.removeFavourite(item.id);
    }
  }

  openAudioPlayer(lessonGroupTitle: string, item: MediaItem) {
    if (item.isDownloading) {
      return this._LocalNotificationsService.showAlert('Downloading', 'Please wait until audio is downloaded, or stop the download to stream it.');
    }

    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: AudioPlayerPage,
      componentProps: {
        subtitle: `${lessonGroupTitle}: ${this.selectedCourse.title}`,
        item: item
      }
    }).then(player => {
      player.present();
    });
  }

  openIntroYouTubePlayer(item: MediaItem) {
    var videoId: string = this._APIService.getYouTubeVideoIdFromString(item.url);
    if (!videoId) { 
      return this._LocalNotificationsService.showToast('Couldn\'t load YouTube video :('); 
    }
    
    this._ModalController.create({
      cssClass: 'full-screen-modal',
      component: YoutubePlayerPage,
      componentProps: {
        item: <YouTubeItem> {
          videoId: videoId
        },
        mediaItemId: item.id,
        markViewedOnClose: true
      }
    }).then(player => {
      player.present();
    });
  }

  showPreviewModal(course: Course) {
    if (course.isComingSoon) {
      this._LocalNotificationsService.showToast("Course details coming soon!");
      return;
    }
    // 11/11/2021 - disable popup page.
    // this._ModalController.create({
    //   cssClass: 'full-screen-modal',
    //   component: CoursePreviewPage,
    //   componentProps: {
    //     course: course
    //   }
    // }).then(preview => {
    //   preview.present();
    // });
  }

  toggleDownload(item: MediaItem, event: Event) {
    if (event) { event.stopPropagation(); }
    this._DownloadService.toggleDownload(item);
  }

  visitCourseWebLink() {
    window.open(this.selectedCourse.courseWebLink, "_blank");
  }
}

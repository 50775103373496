import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams, IonDatetime, ToastController, AlertController, PickerController } from '@ionic/angular';
import { LocalNotifications } from '@capacitor/local-notifications';
import { NotificationFrequency, UserNotifications } from '../interfaces/model';
import { StorageService } from '../services/StorageService';
import { Capacitor } from '@capacitor/core';
import { LocalNotificationsService } from '../services/LocalNotificationsService';

declare var cordova: any;

@Component({
  selector: 'app-settingsReminder',
  templateUrl: 'settingsReminder.page.html',
  styleUrls: ['settingsReminder.page.scss'],
})
export class SettingsReminderPage implements OnInit {

  @ViewChild(IonDatetime) ionDatetime: IonDatetime;

  public customAlertOptions = {
    cssClass: 'full-height-alert'
  };
  


  // Placeholder model for UI.
  public model: UserNotifications = {
    meditationReminderOn: false,
    meditationReminderFrequency: NotificationFrequency.EveryDay,
    meditationReminderTime: '01/01/2020 08:00'
  }

  public frequencyOptions: any = [
    { val: NotificationFrequency.EveryDay, text: 'Every day' },
    { val: NotificationFrequency.Monday, text: 'Monday' },
    { val: NotificationFrequency.Tuesday, text: 'Tuesday' },
    { val: NotificationFrequency.Wednesday, text: 'Wednesday' },
    { val: NotificationFrequency.Thursday, text: 'Thursday' },
    { val: NotificationFrequency.Friday, text: 'Friday' },
    { val: NotificationFrequency.Saturday, text: 'Saturday' },
    { val: NotificationFrequency.Sunday, text: 'Sunday' }
  ];

  constructor(
    public _Params: NavParams,
    public _ModalController: ModalController,
    public _ToastController: ToastController,
    public _StorageService: StorageService,
    public _LocalNotificationsService: LocalNotificationsService
  ) {
  }

  ngOnInit() {
    this._StorageService.getUserNotifications().then(model => {
      if (model) {
        this.model = model;
      }
    });
  }

  askForPermissions() {
    this._LocalNotificationsService.showToast('Ask for permissions');
    LocalNotifications.requestPermissions().then(result => {
      this._LocalNotificationsService.showToast(result.display);
    });
  }


  close() {    
    this._ModalController.dismiss();
  }

  showTimePicker() {
    this.ionDatetime.open();
  }

  timeChanged() {
    this.model.meditationReminderTime = this.ionDatetime.value;
    this.checkPermissions();
  }

  checkPermissions() {
    if (!this.model.meditationReminderOn) {
      this.save();
      return;
    }

    LocalNotifications.checkPermissions().then(result => {
      switch(true) {
        case result.display == "prompt": return this.askForPermissions();
        case result.display == "prompt-with-rationale": return this.askForPermissions();
        case result.display == "denied": return this.askForPermissions();
        case result.display == "granted": return this.save();
      }
    });
  }

  save() {
    this._StorageService.setUserNotifications(this.model);
    if (Capacitor.isNativePlatform()) {
      cordova.plugins.notification.local.cancelAll();
    }

    if (!this.model.meditationReminderOn) {
      return this._LocalNotificationsService.showToast("Reminder turned off");
    }

    this._LocalNotificationsService.scheduleNotifications(this.model, true);
  }

}
